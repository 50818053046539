export default class Header {
  constructor(container) {
    this.container = container;

    this.navMenu = this.container.querySelector(`.nav-menu`);
    this.navMenuToggle = this.navMenu.querySelector(`.nav-menu__button`);

    this.menuButtonClickHandler = this.menuButtonClickHandler.bind(this);
    this.navMenuEscKeyHandler = this.navMenuEscKeyHandler.bind(this);
  }

  init() {
    this.setMenuToggle();
  }

  setMenuToggle() {
    this.navMenuToggle.addEventListener(`click`, this.menuButtonClickHandler);
  }

  openNavMenu() {
    this.navMenu.classList.remove(`nav-menu--closed`);
    this.navMenu.classList.add(`nav-menu--opened`);
  }

  closeNavMenu() {
    this.navMenu.classList.remove(`nav-menu--opened`);
    this.navMenu.classList.add(`nav-menu--closed`);
  }

  menuButtonClickHandler(evt) {
    evt.preventDefault();

    if (this.navMenu.classList.contains(`nav-menu--closed`)) {
      this.openNavMenu();
      document.addEventListener(`keydown`, this.navMenuEscKeyHandler);
    } else {
      this.closeNavMenu();
    }
  }

  navMenuEscKeyHandler(evt) {
    if (evt.key === `Escape` || evt.key === `Esc`) {
      evt.preventDefault();
      this.closeNavMenu();
      document.removeEventListener(`keydown`, this.navMenuEscKeyHandler)
    }
  }
}