export default class Popup {
  constructor(popup, header) {
    this.popup = popup;
    this.header = header;

    this.buttonClose = this.popup.querySelector(`.popup__close`);
    this.overlay = document.querySelector(`.overlay`);
    this.buttonContinue = this.popup.querySelector(`.modal-cart__button--blue`);

    // this.headerCart = this.header.querySelector(`.user-controls__item--cart`);

    this.popupClickHandler = this.popupClickHandler.bind(this);
    this.popupEscHandler = this.popupEscHandler.bind(this);
    this.overlayClickHandler = this.overlayClickHandler.bind(this);
    this.buttonCloseClickHandler = this.buttonCloseClickHandler.bind(this);
    // this.containerClickHandler = this.containerClickHandler.bind(this);
  }

  init() {
    // this.setPopupOpen(this.headerCart);
    // this.setPopupOpen();
  }

  // setPopupOpen(element) {
  //   element.addEventListener(`click`, this.containerClickHandler);
  //   if (this.overlay !== null) {
  //     this.overlay.addEventListener(`click`, this.overlayClickHandler);
  //   }
  // }

  setPopupOpen() {
    if (this.overlay !== null) {
      this.overlay.addEventListener(`click`, this.overlayClickHandler);
    }

    this.openPopupCart();
    this.buttonClose.addEventListener(`click`, this.popupClickHandler);
    document.addEventListener(`keydown`, this.popupEscHandler);
    // if (this.overlay !== null) {
    //   this.overlay.classList.add(`popup__overlay`);
    // }

    if (this.buttonContinue !== null) {
      this.buttonContinue.addEventListener('click', this.buttonCloseClickHandler);
    }
  }

  setOverlay() {
    if (this.overlay !== null) {
      this.overlay.classList.add(`popup__overlay`);
    }
  }

  setClosePopupTimeOut(time) {
    setTimeout(() => {
      this.popupClickHandler();
      document.removeEventListener(`keydown`, this.popupEscHandler);
    }, time)

  }

  closePopup() {
    if (this.popup !== null) {
      if (this.popup.classList.contains(`popup__show`)) {
        this.popup.classList.remove(`popup__show`);
      }
    }
  }

  openPopupCart() {
    if (this.popup !== null) {
      if (!this.popup.classList.contains(`popup__show`)) {
        this.popup.classList.add(`popup__show`);
      }
    }

  }

  popupClickHandler() {
    // evt.preventDefault();
    if (this.overlay !== null) {
      if (this.overlay.classList.contains(`popup__overlay`)) {
        this.overlay.classList.remove(`popup__overlay`);
      }
    }
    this.closePopup();
  }

  popupEscHandler(evt) {
    if (evt.key === `Escape` || evt.key === `Esc`) {
      evt.preventDefault();

      this.closePopup();
      if (this.overlay !== null) {
        this.overlay.classList.remove(`popup__overlay`);
      }
      document.removeEventListener(`keydown`, this.popupEscHandler);
    }
  }

  overlayClickHandler() {
    this.closePopup();
    this.overlay.classList.remove(`popup__overlay`);
    document.removeEventListener(`keydown`, this.popupEscHandler);
  }

  buttonCloseClickHandler(evt) {
    evt.preventDefault();
    this.popupClickHandler();
    document.removeEventListener(`keydown`, this.popupEscHandler);
  }

  // containerClickHandler(evt) {
  //   evt.preventDefault();
  //   this.openPopupCart();
  //   this.buttonClose.addEventListener(`click`, this.popupClickHandler);
  //   document.addEventListener(`keydown`, this.popupEscHandler);
  //   if (this.overlay !== null) {
  //     this.overlay.classList.add(`popup__overlay`);
  //   }
  // }
}