import Popup from "./popup.js";
import Api from "./api.js";
import { getDatasetId, getDatasetQuantity, getOptions, actionMarker } from "../utils.js";

export default class Cart {
  constructor(container) {
    this.container = container;
    this.productList = this.container.querySelectorAll(`.product__item`);
    this.modalCart = document.querySelector(`#modal-cart`);
    if (this.modalCart !== null) {
      this.popupAdd = new Popup(this.modalCart);
    }

    this.buttonAddClickHendler = this.buttonAddClickHendler.bind(this);
    this.buttonCartClickHndler = this.buttonCartClickHndler.bind(this);
  }

  init() {

    if (this.productList !== null) {
      if (this.productList.length > 0) {

        const buttonList = Array
          .from(this.productList)
          .map((el) => el.querySelector(`.product__button`));

        this.productList = Array
          .from(this.productList)
          .filter((el) => el.querySelector(`.product__button`).textContent === 'Купить')

        this.buttonListAdd = Array.from(buttonList).filter((item) => item.textContent === 'Купить');
        this.buttonListAdd.forEach((item, index) => {
          item.addEventListener(`click`, this.buttonAddClickHendler.bind(this, item, index));
        });
      }
    }

    this.buttonCart = this.container.querySelector(`#button-cart`);
    if (this.buttonCart !== null) {
      this.buttonCart.addEventListener(`click`, this.buttonCartClickHndler);
    }
  }

  buttonCartClickHndler(evt) {
    evt.preventDefault();
    const id = this.container.querySelector(`input[name=product_id]`).value;
    const material = this.container.querySelector(`#product`).dataset.material;
    const options = this.getOptionsRoll(material);
    const optionsResult = options ? options : '';

    const api = new Api('https://tdpsm.ru/index.php?route=checkout/cart/add');
    // const api = new Api('http://tdpsm/index.php?route=checkout/cart/add');
    api.add(
      'product_id=' + id + '&quantity=' + this.getQuantity(material) + optionsResult
    ).then((response) => {
      if (response.success) {
        actionMarker(response.total);
        this.popupAdd.setPopupOpen();
        this.popupAdd.setOverlay();
      }
    });
  }

  buttonAddClickHendler(item, index, evt) {
    evt.preventDefault();
    const id = getDatasetId(item);
    const options = getOptions(this.productList[index], id);
    const optionsResult = options ? options : '';
    // const api = new Api('http://tdpsm/index.php?route=checkout/cart/add');
    const api = new Api('https://tdpsm.ru/index.php?route=checkout/cart/add');
    // const api = new Api('http://tdpsm-oc.test-check.website/index.php?route=checkout/cart/add');
    api.add(
      'product_id=' + id + '&quantity=' + getDatasetQuantity(item) + optionsResult
    ).then((response) => {
      if (response.success) {
        actionMarker(response.total);
        this.popupAdd.setPopupOpen();
        this.popupAdd.setOverlay();
      }
    });
  }

  getQuantity(material) {
    if (material !== 'point') {
      return this.container.querySelector(`#product input[type="text"]`).value;
    } else {
      return this.container.querySelector(`#product input[name="opt"]`).value;
    }
  }

  getOptionsRoll(material) {
    if (material === 'roll') {
      const width = this.container.querySelector(`.form-product__select-width--product`).value;
      const cut = this.container.querySelector(`input[type="radio"]`).checked;
      return '&method-width' + "=" + width + '&cut' + "=" + cut;
    } else {
      return null;
    }
  }
}