export default class ScrollApp {
  constructor(header) {
    this.header = header;
    this.buttonApp = this.header.querySelector(`.user-controls__item--app`);

    this.documentScrollHandler = this.documentScrollHandler.bind(this);
    this.buttonAppClickHandler = this.buttonAppClickHandler.bind(this);
  }

  init() {
    window.addEventListener('scroll', this.documentScrollHandler);
    this.buttonApp.addEventListener('click', this.buttonAppClickHandler)
  }

  documentScrollHandler() {
    if (window.pageYOffset > 300) {
      this.buttonApp.style.display = "block";
    } else {
      this.buttonApp.style.display = "none";
    }
  }

  buttonAppClickHandler(evt) {
    evt.preventDefault();
    this.header.scrollIntoView({ block: "center", behavior: "smooth" });
  }
}