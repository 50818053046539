import Slider from "../view/slider.js";

export default class Tabs {
  constructor(main) {
    this.main = main;
    this.tabs = this.main.querySelectorAll(`.tabs__item`);
    this.text = this.main.querySelector(`.tabs__note`);
    this.textNote = this.main.querySelector(`.tabs__text-note`);
    if (this.main.querySelector(`.main-category__arrivals--best`) !== null) {
      this.collection = this.main.querySelector(`.main-category__arrivals--best`);
    }
    this.product = this.main.querySelector(`.main-category__arrivals--arrivals`);

    this.tabsClickHandler = this.tabsClickHandler.bind(this);
  }

  init(siteWidth) {
    // window.addEventListener(`unload`, () => {
    //   localStorage.setItem(`scrollTab`, `end`)
    // })

    const collection = this.main.querySelector(`.main-category__arrivals--best`);
    const product = this.main.querySelector(`.main-category__arrivals--arrivals`);
    const sections = [product, collection];
    this.initSliderCollection(siteWidth);

    const scrollTab = localStorage.getItem(`scrollTab`);
    if (scrollTab === `product`) {
      document.addEventListener(`DOMContentLoaded`, () => {
        this.product.scrollIntoView({ block: "start", behavior: "auto" });
      });
    }

    if (this.main.querySelector(`.collection`) !== null) {
      this.storageTabsCategory = localStorage.getItem(`tabsCategory`);
      this.getStorageTabs(this.storageTabsCategory, this.tabs, `tabsCategory`, sections, siteWidth);

      Array.from(this.tabs)
        .forEach((item, index) => {
          item.addEventListener(`click`, this.tabsClickHandler.bind(this, index, `tabsCategory`))
        });
    }
    else {
      this.storageTabsSubCategory = localStorage.getItem(`tabsSubCategory`);
      this.getStorageTabs(this.storageTabsSubCategory, this.tabs, `tabsSubCategory`, sections, siteWidth);

      Array.from(this.tabs)
        .forEach((item, index) => {
          item.addEventListener(`click`, this.tabsClickHandler.bind(this, index, `tabsSubCategory`))
        });
    }

    Array.from(this.tabs).forEach((item) => {
      item.addEventListener(`click`, (evt) => {
        if (evt.target.dataset.tab === 'product' && !evt.target.classList.contains(`tabs__item--activ`)) {
          product.style.display = 'block';
          this.text.textContent = 'Коллекции';
          this.textNote.textContent = 'коллекции';
          if (collection !== null) {
            collection.style.display = 'none';
          }

          Array.from(this.tabs).forEach((li) => {
            if (li.dataset.tab === 'product') {
              li.classList.add(`tabs__item--activ`);
            }
            if (li.dataset.tab === 'collection') {
              li.classList.remove(`tabs__item--activ`);
            }
          });

          this.initSliderProduct(siteWidth);

          // this.openSectionProduct(siteWidth);
        }

        if (evt.target.dataset.tab === 'collection' && !evt.target.classList.contains(`tabs__item--activ`)) {

          product.style.display = 'none';
          this.text.textContent = 'Товары';
          this.textNote.textContent = 'товары';
          if (collection !== null) {
            collection.style.display = 'block';
          }

          Array.from(this.tabs).forEach((li) => {
            if (li.dataset.tab === 'collection') {
              li.classList.add(`tabs__item--activ`);
            }
            if (li.dataset.tab === 'product') {
              li.classList.remove(`tabs__item--activ`);
            }
          });

          this.initSliderCollection(siteWidth);
        }
      });
    });
  }

  openSectionProduct(siteWidth) {
    this.product.style.display = 'block';
    this.collection.style.display = 'none';

    Array.from(this.tabs).forEach((li) => {
      if (li.dataset.tab === 'product') {
        li.classList.add(`tabs__item--activ`);
      }
      if (li.dataset.tab === 'collection') {
        li.classList.remove(`tabs__item--activ`);
      }
    });

    this.initSliderProduct(siteWidth);
    localStorage.setItem(`scrollTab`, `product`);
  }

  initSliderProduct(siteWidth) {
    if (siteWidth < 1185) {
      const latestProduct = this.main.querySelector(`.main-category__arrivals--arrivals`);
      if (latestProduct !== null) {
        const containerSliderProductLatest = latestProduct.querySelector(`.arrivals__container`);
        const trackSliderProductLatest = containerSliderProductLatest.querySelector(`.arrivals__list`);
        const itemSliderProductLatest = containerSliderProductLatest.querySelectorAll(`.product__item-wrap`);
        const btnWrapProductLatest = latestProduct.querySelector(`.arrivals__button-wrap`);
        const btnPrevProductLatest = btnWrapProductLatest.querySelector(`.button-slider__button--prev`);
        const btnNextProductLatest = btnWrapProductLatest.querySelector(`.button-slider__button--next`);
        let slidesToShowProductLatest = 1;
        const slidesToScrollProductLatest = 1;

        // if (siteWidth > 1186) {
        //   slidesToShowProductLatest = 3;
        // } else if (siteWidth > 767) {
        //   slidesToShowProductLatest = 2;
        // }
        if (siteWidth > 767) {
          slidesToShowProductLatest = 2;
        }

        const PERIOD_SLIDER_LATEST = 5000;
        const START_TIME_SLIDER_LATEST = 2000;
        const sliderDotLictLATEST = null;

        const sliderProductLatest = new Slider(containerSliderProductLatest, trackSliderProductLatest, itemSliderProductLatest, btnPrevProductLatest, btnNextProductLatest, slidesToShowProductLatest, slidesToScrollProductLatest, PERIOD_SLIDER_LATEST, START_TIME_SLIDER_LATEST, sliderDotLictLATEST);
        sliderProductLatest.init();

      }
    }
  }

  initSliderCollection(siteWidth) {
    if (siteWidth < 768) {

      const bestCollection = this.main.querySelector(`.main-category__arrivals--best`);
      if (bestCollection !== null) {
        const containerSliderCollectionBest = bestCollection.querySelector(`.arrivals__container`);
        const trackSliderCollectionBest = containerSliderCollectionBest.querySelector(`.arrivals__list`);
        const itemSliderCollectionBest = containerSliderCollectionBest.querySelectorAll(`.slider-category__wrap`);
        const btnWrapCollectionBest = bestCollection.querySelector(`.arrivals__button-wrap`);
        const btnPrevCollectionBest = btnWrapCollectionBest.querySelector(`.button-slider__button--prev`);
        const btnNextCollectionBest = btnWrapCollectionBest.querySelector(`.button-slider__button--next`);
        let slidesToShowCollectionBest = 1;
        const slidesToScrollCollectionBest = 1;

        const PERIOD_SLIDER_BEST = 5000;
        const START_TIME_SLIDER_BEST = 2000;
        const sliderDotLictBEST = null;

        const sliderCollectionBest = new Slider(containerSliderCollectionBest, trackSliderCollectionBest, itemSliderCollectionBest, btnPrevCollectionBest, btnNextCollectionBest, slidesToShowCollectionBest, slidesToScrollCollectionBest, PERIOD_SLIDER_BEST, START_TIME_SLIDER_BEST, sliderDotLictBEST);
        sliderCollectionBest.init();
      }
    }
  }

  getStorageTabs(storageTabs, box, nameStorage, sections, siteWidth) {
    if (JSON.parse(storageTabs) === null) {
      storageTabs = [];
      Array
        .from(box)
        .map((i) => {
          if (i.classList.contains(`tabs__item--activ`)) {
            storageTabs.push(i.storage = true);
          } else {
            storageTabs.push(i.storage = false);
          }
        });
      localStorage.setItem(nameStorage, JSON.stringify(storageTabs));

    } else {
      Array
        .from(box)
        .forEach((item, index) => {
          if (JSON.parse(storageTabs)[index]) {
            if (!item.classList.contains(`tabs__item--activ`)) {
              item.classList.add(`tabs__item--activ`);
              if (sections[index] !== null) {
                sections[index].style = "display:block;";
              }
              item.dataset.tab === 'product'
                ? this.initSliderProduct(siteWidth)
                : ''
            }
          } else {

            if (item.classList.contains(`tabs__item--activ`)) {
              item.classList.remove(`tabs__item--activ`);

              if (sections[index] !== null) {
                sections[index].style = "display:none;";
              }
              item.dataset.tab === 'product'
                ? this.initSliderProduct(siteWidth)
                : ''
            }
          }
        });
    }
  }

  tabsClickHandler(index, NameStorage) {
    const row = localStorage.getItem(NameStorage);
    const arrStorage = JSON.parse(row);
    if (!arrStorage[index]) {
      let tempStorage = []
      arrStorage.map((i) => {
        i = false;
        tempStorage.push(i);
      });
      tempStorage[index] = true;
      localStorage.setItem(NameStorage, JSON.stringify(tempStorage));
    }
  }
}