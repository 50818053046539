
export default class Slider {
  constructor(container, trackSlider, item, btnPrev, btnNext, slidesToShow, slidesToScroll, PERIOD_SLIDER, START_TIME_SLIDER, sliderDotList) {
    this.container = container;
    this.trackSlider = trackSlider;
    this.item = item;
    this.btnPrev = btnPrev;
    this.btnNext = btnNext;
    this.slidesToShow = slidesToShow;
    this.slidesToScroll = slidesToScroll;
    this.PERIOD_SLIDER = PERIOD_SLIDER;
    this.START_TIME_SLIDER = START_TIME_SLIDER;

    this.sliderDotListCommon = sliderDotList;
    if (this.sliderDotListCommon) {
      this.itemsDot = this.sliderDotListCommon.querySelectorAll(`li`);
    }

    this.itemCount = this.item.length;
    this.itemWidth = this.container.offsetWidth / this.slidesToShow;
    this.position = 0;
    this.movePosition = this.slidesToScroll * this.itemWidth;
    this.ultimateWidth = (this.itemCount - this.slidesToShow) * this.itemWidth;
    this.dotNumber = 0;

    this.btnPrevClickHandler = this.btnPrevClickHandler.bind(this);
    this.btnNextClickHandler = this.btnNextClickHandler.bind(this);
    this.movingNext = this.movingNext.bind(this);
    this.movingPrev = this.movingPrev.bind(this);
    this.itemDotClickHandler = this.itemDotClickHandler.bind(this);
  }

  init() {
    this.item.forEach(element => {
      element.style.minWidth = this.itemWidth + "px";
    });
    if (this.sliderDotListCommon !== null) {
      this.initActivDot();
      // this.initClickDot(); //! можно подключать, когда нет автопрокрутки по времени
    }
    this.btnPrev.addEventListener(`click`, this.btnPrevClickHandler);
    this.btnNext.addEventListener(`click`, this.btnNextClickHandler);

    this.checkBtns();
  }

  trafficPrev() {
    // console.log('posP', this.position)
    const itemLeft = (Math.abs(this.position)) / this.itemWidth;
    this.position += itemLeft >= this.slidesToScroll ? this.movePosition : itemLeft * this.itemWidth;
    // console.log('posP', this.position)
    if (this.sliderDotListCommon !== null) {
      this.dotNumber > 0 && this.dotNumber <= this.itemsDot.length - 1 ? this.dotNumber -= 1 : ``
      this.initActivDot();
    }
  }

  trafficNext() {
    // console.log('posN', this.position)
    const itemLeft = this.itemCount - (Math.abs(this.position) + this.slidesToShow * this.itemWidth) / this.itemWidth;
    this.position -= itemLeft >= this.slidesToScroll ? this.movePosition : itemLeft * this.itemWidth;
    // console.log('posN', this.position)
    if (this.sliderDotListCommon !== null) {
      this.dotNumber >= 0 && this.dotNumber < this.itemsDot.length - 1 ? this.dotNumber += 1 : ``
      this.initActivDot();
    }
  }

  setPosition(position) {
    this.trackSlider.style.transform = `translateX(${position}px)`
  }

  checkBtns() {
    this.btnPrev.disabled = this.position === 0;
    this.btnNext.disabled = this.position <= -this.ultimateWidth;

    this.btnPrev.disabled ? this.btnPrev.style.opacity = "0.3" : this.btnPrev.style.opacity = "1";
    this.btnNext.disabled ? this.btnNext.style.opacity = "0.3" : this.btnNext.style.opacity = "1";
  }

  btnPrevClickHandler() {
    // console.log('clickPrev')
    this.trafficPrev();
    this.setPosition(this.position)
    this.checkBtns();
  }

  btnNextClickHandler() {
    this.trafficNext();
    this.setPosition(this.position)
    this.checkBtns();
  }

  initScrollSlider() {
    setTimeout(this.movingNext, this.START_TIME_SLIDER);
  }

  movingNext() {
    this.trafficNext();
    this.setPosition(this.position);
    this.checkBtns();

    this.isEndFromLeft() ? this.autoSliderPrev() : this.autoSliderNext();
  }

  movingPrev() {
    this.trafficPrev();
    this.setPosition(this.position);
    this.checkBtns();
    this.isEndFromRight() ? this.autoSliderNext() : this.autoSliderPrev();
  }

  isEndFromLeft() {
    return Math.abs(this.position) === this.ultimateWidth;
  }

  isEndFromRight() {
    return Math.abs(this.position) === 0;
  }

  autoSliderPrev() {
    setTimeout(this.movingPrev, this.PERIOD_SLIDER);
  }

  autoSliderNext() {
    setTimeout(this.movingNext, this.PERIOD_SLIDER);
  }

  resetDotList(sliderDotlist) {
    if (this.sliderDotListCommon !== null) {
      const itemsDot = sliderDotlist.querySelectorAll(`li`);
      itemsDot.forEach((item) => {
        item.classList.contains(`slider-common__dot-item--activ`) ? item.classList.remove(`slider-common__dot-item--activ`) : ``;
      });
    }
  }

  numberDot() {
    this.dotNumber = ((Math.abs(this.position)) / this.itemWidth);
  }


  initActivDot() {
    if (this.sliderDotListCommon !== null) {

      this.resetDotList(this.sliderDotListCommon)
      this.itemsDot[this.dotNumber].classList.add(`slider-common__dot-item--activ`)
    }
    this.numberDot()
  }

  initClickDot() {
    this.sliderDotListCommon.addEventListener(`click`, this.itemDotClickHandler)
  }

  itemDotClickHandler(evt) {
    const result = this.itemWidth - (evt.target.dataset.sliderDot * this.itemWidth)
    this.setPosition(result)


    this.resetDotList(this.sliderDotListCommon)
    this.itemsDot[evt.target.dataset.sliderDot - 1].classList.add(`slider-common__dot-item--activ`)
  }
}