import GalleryRoll from "./gallery_roll.js";
import Popup from "./popup.js";

export default class Category {
  constructor(container, siteWidth) {
    this.container = container;

    this.categoryContainer = this.container.querySelector(`.catalog`);
    this.categoryToggle = this.categoryContainer.querySelector(`.catalog__button-wrap`);
    this.siteWidth = siteWidth;
    this.modalChoice = this.container.querySelector(`#modal-choice`);
    if (this.modalChoice !== null) {
      this.popupChoice = new Popup(this.modalChoice);
    }
    this.buttonChoice = this.container.querySelector(`.selection-banner__link`);

    this.categoryClickHandler = this.categoryClickHandler.bind(this);
    this.categoryEscKeyHandler = this.categoryEscKeyHandler.bind(this);
    this.buttonChoiceClickHandler = this.buttonChoiceClickHandler.bind(this);
  }

  init() {
    this.setCategoryToggle();

    if (this.buttonChoice !== null) {
      this.buttonChoice.addEventListener(`click`, this.buttonChoiceClickHandler)
    }
    const availableProduts = this.container.querySelector(`.main-category__arrivals--arrivals`);
    if (availableProduts !== null) {
      const available = new GalleryRoll(availableProduts);
      available.init();
    }

    // const categoryLatest = this.container.querySelector(`#category_latest`);
    // if (categoryLatest !== null) {
    //   const latestInCategore = new GalleryRoll(categoryLatest);
    //   latestInCategore.init();
    // }
  }

  setCategoryToggle() {
    if (this.siteWidth < 1290) {
      this.categoryToggle.addEventListener(`click`, this.categoryClickHandler);
    }
  }

  openCategory() {
    this.categoryContainer.classList.remove(`catalog--closed`);
    this.categoryContainer.classList.add(`catalog--opened`);
  }

  closeCategory() {
    this.categoryContainer.classList.remove(`catalog--opened`);
    this.categoryContainer.classList.add(`catalog--closed`);
  }

  categoryClickHandler(evt) {
    evt.preventDefault();

    if (this.categoryContainer.classList.contains(`catalog--closed`)) {
      this.openCategory();
      document.addEventListener(`keydown`, this.categoryEscKeyHandler);
    } else {
      this.closeCategory();
    }
  }

  categoryEscKeyHandler(evt) {
    evt.preventDefault();
    this.closeCategory();
    document.removeEventListener(`keydown`, this.categoryEscKeyHandler);
  }

  buttonChoiceClickHandler(evt) {
    evt.preventDefault();
    this.popupChoice.setPopupOpen();
    this.popupChoice.setOverlay();
  }
}