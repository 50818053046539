import FilterPrice from "./filter_price.js";

export default class Filter {
  constructor(container, siteWidth) {
    this.container = container;
    this.siteWidth = siteWidth;
    this.header = this.container.querySelector(`.filter__header`);
    this.fieldsetBox = this.container.querySelectorAll(`.filter__box`);
    this.buttonBox = this.container.querySelectorAll(`.filter__fieldset-wrap`);
    this.valueText = this.container.querySelectorAll(`input[type=text]`);
    this.valueCheckbox = this.container.querySelectorAll(`input[type=checkbox]`);
    this.url = document.location.pathname;
    this.buttonRed = this.container.querySelector(`#button-filter-new`);

    this.isStorageSupport = true;
    this.storageOpenFilter = false;
    // this.storageOpenFieldsets = [];

    this.headerFilterClickHandler = this.headerFilterClickHandler.bind(this);
    this.buttonClickHandler = this.buttonClickHandler.bind(this);
    this.priceTextInputHandler = this.priceTextInputHandler.bind(this);
    this.checkboxInputHandler = this.checkboxInputHandler.bind(this);
    this.clickButtonResetHendler = this.clickButtonResetHendler.bind(this);
    this.buttonRedClickHandler = this.buttonRedClickHandler.bind(this);
  }

  init() {
    this.storageUrl = localStorage.getItem(`url`);
    this.buttonRed.addEventListener(`click`, this.buttonRedClickHandler);

    if (JSON.parse(this.storageUrl) === null) {
      localStorage.setItem(`url`, JSON.stringify(this.url));
    } else if (JSON.parse(this.storageUrl) !== this.url) {
      localStorage.setItem(`url`, JSON.stringify(this.url));
      this.setStorageInputCheckbox();
    }

    this.storageInputCheckbox = localStorage.getItem(`inputCheckbox`);
    if (this.siteWidth < 1185) {

      try {
        this.storageOpenFilter = localStorage.getItem(`filterOpen`);
        this.storageOpenFieldsets = localStorage.getItem(`fieldsetOpen`);
        // this.storageInputText = localStorage.getItem(`inputText`);
      } catch (err) {
        this.isStorageSupport = false;
        console.error('error ', err)
      }

      this.correctOpenFilter();
      this.correctOpenFieldsets();
      this.header.addEventListener(`click`, this.headerFilterClickHandler);
      // this.setValueInputText();
      // this.correctStorageInputText();

      Array
        .from(this.fieldsetBox)
        .forEach((item, index) => {
          this.openFieldset(item, this.buttonBox[index], index);
        });
    }
    this.getInputCheckbox();
    this.correctStorageInputCheckbox();

    if (this.siteWidth > 1186) {
      const filterPrice = new FilterPrice(this.container);
      filterPrice.init();
    } else {
      const openFilterPrice = this.container.querySelector(`.filter__fieldset--price`).classList.contains(`filter__fieldset--show`);

      if (!openFilterPrice) {
        this.container.querySelector(`.filter__fieldset--price`).addEventListener(`click`, (evt) => {
          evt.preventDefault();
          const filterPrice = new FilterPrice(this.container);
          filterPrice.init();
        });
      } else {
        const filterPrice = new FilterPrice(this.container);
        filterPrice.init();
      }
    }

    this.container.querySelector(`button[type=reset]`).addEventListener(`click`, this.clickButtonResetHendler);
  }

  getInputCheckbox() {
    if (JSON.parse(this.storageInputCheckbox) === null) {

      this.setStorageInputCheckbox();
    } else {
      Array
        .from(this.valueCheckbox)
        .forEach((i, index) => {
          i.checked = JSON.parse(this.storageInputCheckbox)[index];
        });
    }
  }

  setStorageInputCheckbox() {
    const row = [];
    Array
      .from(this.valueCheckbox)
      .map((item) => {
        row.push(item.storage = false);
      });
    localStorage.setItem(`inputCheckbox`, JSON.stringify(row));
  }

  setValueInputText() {
    if (JSON.parse(this.storageInputText) === null) {
      const row = [];
      Array.from(this.valueText).map((item) => {
        row.push(item.value);
      });
      localStorage.setItem(`inputText`, JSON.stringify(row));
    } else {
      Array.from(this.valueText).forEach((item, index) => {
        item.value = JSON.parse(this.storageInputText)[index]
      });
    }
  }

  openFieldset(fieldset, button, index) {
    button.addEventListener(`click`, this.buttonClickHandler.bind(this, fieldset, button, index));
  }

  correctStorageInputCheckbox() {
    Array
      .from(this.valueCheckbox)
      .forEach((item, index) => {
        item.addEventListener(`change`, this.checkboxInputHandler.bind(this, index))
      });
  }

  correctStorageInputText() {
    Array
      .from(this.valueText)
      .forEach((item, index) => {
        item.addEventListener(`input`, this.priceTextInputHandler.bind(this, index))
      });
  }

  correctOpenFilter() {
    if (this.storageOpenFilter === 'true') {
      this.container.classList.add(`filter__show`);
    }
  }

  correctOpenFieldsets() {
    if (JSON.parse(this.storageOpenFieldsets) === null) {
      this.storageOpenFieldsets = [];
      Array
        .from(this.fieldsetBox)
        .map((item) => {
          this.storageOpenFieldsets.push(item.storage = false);
        });
      localStorage.setItem(`fieldsetOpen`, JSON.stringify(this.storageOpenFieldsets));
    } else {
      Array
        .from(this.fieldsetBox)
        .forEach((item, index) => {
          if (JSON.parse(this.storageOpenFieldsets)[index]) {
            item.classList.remove(`filter__hidden`);
            this.buttonBox[index].classList.add(`filter__fieldset--show`);
          }
        });
    }
  }

  headerFilterClickHandler() {
    this.container.classList.toggle(`filter__show`);

    this.storageOpenFilter = !JSON.parse(this.storageOpenFilter);

    localStorage.setItem(`filterOpen`, this.storageOpenFilter);
    this.isStorageSupport = true;
  }

  buttonClickHandler(fieldset, button, index) {
    fieldset.classList.toggle(`filter__hidden`);
    button.classList.toggle(`filter__fieldset--show`);

    const row = localStorage.getItem(`fieldsetOpen`);
    const arrStorage = JSON.parse(row);
    arrStorage[index] = !arrStorage[index];
    localStorage.setItem(`fieldsetOpen`, JSON.stringify(arrStorage));
  }

  priceTextInputHandler(index, evt) {
    const storage = JSON.parse(localStorage.getItem(`inputText`));
    storage[index] = evt.target.value;
    localStorage.setItem(`inputText`, JSON.stringify(storage));
  }

  checkboxInputHandler(index, evt) {
    const storage = JSON.parse(localStorage.getItem(`inputCheckbox`));
    storage[index] = evt.target.checked;
    localStorage.setItem(`inputCheckbox`, JSON.stringify(storage));
  }

  clickButtonResetHendler() {
    this.setStorageInputCheckbox();
  }

  buttonRedClickHandler(evt) {
    evt.preventDefault();
    const filterChecked = this.container.querySelectorAll(`input[name='filter[]']:checked`);
    const filter = [];

    Array.from(filterChecked).forEach((input) => {
      filter.push(input.value);
    });

    const minPrice = this.container.querySelector(`input[name='minPrice']`).value;
    const maxPrice = this.container.querySelector(`input[name='maxPrice']`).value;
    const action = document.querySelector(`input[name='action']`).value;

    let url = action + '?&filter=' + filter.join(',');

    if (minPrice != "" && maxPrice != "") {
      url += '&filter_price=' + minPrice + ',' + maxPrice;
    }

    location = url;
  }
}