const Method = {
  GET: `GET`,
  PUT: `PUT`,
  POST: `POST`
}

const SuccessStatus = {
  MIN: 200,
  MAX: 299
}

export default class Api {
  constructor(url) {
    this.url = url;
  }

  getTasks() {
    return this.load({ url: this.url })
      .then(Api.toJSON);
  }

  add(data) {
    return this.load({
      method: Method.POST,
      // body: JSON.stringify(data),
      body: data,
      headers: new Headers({ "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" })
    })
      .then(Api.toJSON);
  }

  addCheap(data) {
    return this.load({
      method: Method.POST,
      body: JSON.stringify(data),
      // body: data,
      headers: new Headers({ "Content-type": "application/x-www-form-urlencoded; charset=UTF-8" })
    })
      .then(Api.toJSON);
  }

  load({
    // url,
    method,
    body = null,
    // method,
    // body,
    headers = new Headers()
  }) {

    // headers.append()
    return fetch(
      `${this.url}`,
      { method, body, headers }
    )
      .then(Api.checkStatus)
      .catch(Api.cathError);
  }

  static checkStatus(response) {
    if (
      response.status < SuccessStatus.MIN ||
      response.status >= SuccessStatus.MAX
    ) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    return response;
  }

  static toJSON(response) {
    return response.json();
  }

  static cathError(err) {
    throw err;
  }
}

