import { disableButton } from "../utils.js";
import { anableButton } from "../utils.js";

export default class FilterPrice {
  constructor(container) {
    this.container = container;

    this.bar = container.querySelector('.filter__bar');
    this.inputMin = this.container.querySelector('.filter__input--min');
    this.inputMax = this.container.querySelector('.filter__input--max');
    this.errorMin = this.container.querySelector('.filter__error--min');
    this.errorMax = this.container.querySelector('.filter__error--max');

    this.inputConstMin = this.inputMin.dataset.minPrice;
    this.inputConstMax = this.inputMax.dataset.maxPrice;

    this.scale = this.container.querySelector(`.filter__scale`).offsetWidth;
    this.toggleMin = this.container.querySelector('.filter__toggle--min');
    this.toggleMax = this.container.querySelector('.filter__toggle--max');
    this.lengthScale = this.inputConstMax - this.inputConstMin;
    this.priceScale = this.lengthScale / this.scale;

    this.inputPriceChangeHandler = this.inputPriceChangeHandler.bind(this);
    this.submitPriceFilterClickHandler = this.submitPriceFilterClickHandler.bind(this);
  }

  init() {
    if (this.inputConstMax === this.inputConstMin) {
      this.container.querySelector(`.filter__fieldset--price`).closest(`.filter__fieldset`).style.display = 'none';
      return;
    }

    this.setToggle();
    this.scaleBar();

    Array
      .from(this.container.querySelectorAll(`input[type=number]`))
      .forEach((input) => {
        input.addEventListener(`input`, this.inputPriceChangeHandler);
      });

    this.initToggleDropDown(this.toggleMin);
    this.initToggleDropDown(this.toggleMax);
    this.touchDropDownMin()
    this.touchDropDownMax()
    this.container.querySelector(`.filter__button--red`).addEventListener(`click`, this.submitPriceFilterClickHandler);
  }

  setToggle() {
    if (this.valueInputMin() >= this.inputConstMin) {
      this.changeToggle(this.valueInputMin(), this.scale, this.lengthScale, this.toggleMin);
    } else {
      this.changeToggle(this.inputConstMin, this.scale, this.lengthScale, this.toggleMin);
    }

    if (!this.valueInputMax() > this.inputConstMax) {
      this.changeToggle(this.valueInputMax(), this.scale, this.lengthScale, this.toggleMax);
    } else {
      this.changeToggle(this.inputConstMax, this.scale, this.lengthScale, this.toggleMax);
    }
  }

  getToggle() {
    this.changeToggle(this.valueInputMin(), this.scale, this.lengthScale, this.toggleMin);
    this.changeToggle(this.valueInputMax(), this.scale, this.lengthScale, this.toggleMax);
  }


  changeToggle(result, scale, intervalPrice, element) {
    const value = ((result - this.inputConstMin) * scale) / intervalPrice;
    return this.changeLeft(element, value);
  }

  changeLeft(element, left) {
    element.style.left = left + 'px';
  }

  inputsForm() {
    return Array.from(this.container.querySelectorAll(`input[type=number]`));
  }

  valueInputMin() {
    return Number(this.inputsForm()[0].value);
  }

  valueInputMax() {
    return Number(this.inputsForm()[1].value);
  }

  scaleBar() {
    if (this.valueInputMin() >= this.inputConstMin) {
      var left = ((this.valueInputMin() - this.inputConstMin) * this.scale) / this.lengthScale;
    } else {
      this.inputMin.value = this.inputConstMin
      left = 0;
    }

    if (this.valueInputMax() < Number(this.inputConstMax) && this.valueInputMax() > 0) {

      var right = this.scale - ((this.valueInputMax() - this.inputConstMin) * this.scale) / this.lengthScale;
    } else {
      this.inputMax.value = this.inputConstMax
      right = 0;
    }

    if (this.valueInputMin() > this.valueInputMax()) {
      left = ((this.valueInputMax() - this.inputConstMin) * this.scale) / this.lengthScale;
      right = this.scale - ((this.valueInputMin() - this.inputConstMin) * this.scale) / this.lengthScale;
    }

    this.bar.style.marginLeft = left + 'px';
    this.bar.style.marginRight = right + 'px';
  }

  initValidatorInputs() {
    const isValidMin = this.validatorInputs(this.valueInputMin(), this.inputMin, this.errorMin);
    const isValidMax = this.validatorInputs(this.valueInputMax(), this.inputMax, this.errorMax);

    if (isValidMin && isValidMax) {
      anableButton(this.container.querySelector(`.filter__button--red`));
    } else {
      disableButton(this.container.querySelector(`.filter__button--red`));
    }

    return isValidMin && isValidMax;
  }

  validatorInputs(value, input, inputError) {
    // validatorInputs(value, input) {
    var num = String(value)

    var dataInput = [
      { condition: value < 0, errorText: 'Значение не может быть отрицательным' },
      { condition: value < this.inputConstMin, errorText: 'Значение должно быть больше минимального значения ' + this.inputConstMin },
      { condition: value > this.inputConstMax, errorText: 'Значение не может быть больше максимального значения ' + this.inputConstMax },
      { condition: !num.match(/[0-9]/g), errorText: 'Значение должно быть цифрами' }
    ]
    var dataInputErrText = []

    dataInput.forEach(function (i) {

      if (i.condition) {
        dataInputErrText.push(i.errorText)
      }
    })

    if (dataInputErrText.length !== 0) {
      input.style.border = '1px solid red'
      inputError.textContent = dataInputErrText.join(' и ')
      return false;
    } else {
      input.style.border = 'none'
      inputError.textContent = ''
      return true;
    }
  }

  inputPriceChangeHandler() {

    if (this.initValidatorInputs()) {
      this.getToggle();
      this.scaleBar();
    }
  }

  initToggleDropDown(element) {

    element.addEventListener(`mousedown`, (evt) => {
      evt.preventDefault()

      var x = evt.clientX
      console.log('x ', x)
      const toggleMousemoveHandler = (moveEvt) => {
        moveEvt.preventDefault()

        const shift = x - moveEvt.clientX;
        x = moveEvt.clientX;
        // console.log('x1 ', x)
        const res = element.offsetLeft - shift;
        // console.log('shift ', shift)
        if (res > 0 && res <= this.scale) {
          if (element === this.toggleMin) {
            this.inputMin.value = Math.round(this.priceScale * res) + Number(this.inputConstMin);
          } else {
            this.inputMax.value = Number(this.inputConstMax) - (this.lengthScale - Math.round(this.priceScale * res))
          }

          element.style.left = (element.offsetLeft - shift) + 'px';
          // console.log('offsetLeft ', element.offsetLeft, shift)
          this.scaleBar();
          this.initValidatorInputs();
        }
      }

      const toggleMouseupHandler = function (evtUp) {
        evtUp.preventDefault();
        document.removeEventListener(`mousemove`, toggleMousemoveHandler);
        document.removeEventListener('mouseup', toggleMouseupHandler);
      }

      document.addEventListener(`mousemove`, toggleMousemoveHandler);
      document.addEventListener('mouseup', toggleMouseupHandler);
    });


  }

  touchDropDownMin() {
    // element.addEventListener(`touchstart`, (evt) => {
    this.toggleMin.addEventListener(`touchstart`, (evt) => {
      // evt.preventDefault();

      var x = evt.changedTouches[0].clientX

      const toggleTouchMovehandler = (moveEvt) => {
        // moveEvt.preventDefault()
        const shift = x - moveEvt.changedTouches[0].clientX;
        x = moveEvt.changedTouches[0].clientX;

        const res = this.toggleMin.offsetLeft - shift;

        if (res > 0 && res <= this.scale) {
          this.inputMin.value = Math.round(this.priceScale * res) + Number(this.inputConstMin);

          this.toggleMin.style.left = (this.toggleMin.offsetLeft - shift) + 'px';
          this.scaleBar();
          this.initValidatorInputs();

          // console.log('rad ', moveEvt.changedTouches[0])
          // console.log('offsetLeft ', this.toggleMin.offsetLeft, shift, res)
        }


      }

      const toggleTouchUpHandler = function (evtUp) {
        evtUp.preventDefault();
        document.removeEventListener(`touchmove`, toggleTouchMovehandler);
        document.removeEventListener('touchend', toggleTouchUpHandler);
      }

      document.addEventListener(`touchmove`, toggleTouchMovehandler);
      document.addEventListener('touchend', toggleTouchUpHandler);
    });
  }

  touchDropDownMax() {
    // element.addEventListener(`touchstart`, (evt) => {
    this.toggleMax.addEventListener(`touchstart`, (evtMax) => {
      // evt.preventDefault();

      var x = evtMax.changedTouches[0].clientX

      const toggleTouchMoveMaxHandler = (moveEvtMax) => {
        // moveEvt.preventDefault()

        const shift = x - moveEvtMax.changedTouches[0].clientX;

        x = moveEvtMax.changedTouches[0].clientX;

        const res = this.toggleMax.offsetLeft - shift;

        if (res > 0 && res <= this.scale) {
          this.inputMax.value = Number(this.inputConstMax) - (this.lengthScale - Math.round(this.priceScale * res))

          this.toggleMax.style.left = (this.toggleMax.offsetLeft - shift) + 'px';
          this.scaleBar();
          this.initValidatorInputs();

          // console.log('rad ', moveEvt.changedTouches[0])
          // console.log('offsetLeft ', this.toggleMin.offsetLeft, shift, res)
        }


      }

      const toggleTouchUpMaxHandler = function (evtUp) {
        evtUp.preventDefault();
        document.removeEventListener(`touchmove`, toggleTouchMoveMaxHandler);
        document.removeEventListener('touchend', toggleTouchUpMaxHandler);
      }

      document.addEventListener(`touchmove`, toggleTouchMoveMaxHandler);
      document.addEventListener('touchend', toggleTouchUpMaxHandler);
    });
  }

  submitPriceFilterClickHandler() {

    if (this.initValidatorInputs()) {
      if (this.valueInputMin() > this.valueInputMax()) {

        var inputTemp = this.valueInputMin();
        this.inputMin.value = this.valueInputMax();
        this.inputMax.value = inputTemp;
      }
      this.getToggle();
      this.scaleBar();
    }
  }
}