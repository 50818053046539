import Header from "./view/header.js";
import ScrollApp from "./view/scroll_app.js";
import Category from "./view/category.js";
import Slider from "./view/slider.js";
import Product from "./view/product.js";
import Cart from "./view/cart.js";
import CheckoutCart from "./view/checkout_cart.js";
import Filter from "./view/filter.js";
import Tabs from "./view/tabs.js";
import Wishlist from "./view/wishlist.js";
import Compare from "./view/compare.js";
import { getSliderStandartArrivals } from "./utils.js";

const headerContainer = document.querySelector(`.header`);
const main = document.querySelector(`main`);

const siteWidth = document.documentElement.clientWidth;

const header = new Header(headerContainer);
header.init();

const scrollApp = new ScrollApp(headerContainer);
scrollApp.init();

const cart = new Cart(main);
cart.init();

const tabs = new Tabs(main);

const wishlist = new Wishlist();
wishlist.init();

const compare = new Compare();
compare.init();

const getLocationSearch = () => {
  let url = document.querySelector(`base`).href + 'index.php?route=product/search';
  const value = document.querySelector(`input[name='search']`).value;
  if (value) {
    url += '&search=' + encodeURIComponent(value);
    return location = url;
  }
}

const serchButton = document.querySelector(`input[name='search']`).parentElement.getElementsByTagName('button')
serchButton[0].addEventListener(`click`, function () {
  getLocationSearch();
});
const serchInput = document.querySelector(`input[name='search']`);
serchInput.addEventListener(`keydown`, function (evt) {
  if (evt.keyCode === 13) {
    getLocationSearch();
  }
});

if (main.querySelector(`.filter`) !== null) {
  const filterBox = main.querySelector(`.filter`);
  const filter = new Filter(filterBox, siteWidth);
  filter.init();
  const button = filterBox.querySelector(`.filter__button--red`);

  if (main.classList.contains(`main-category`)) {

    if (main.querySelector(`.collection`) !== null) {
      button.addEventListener(`click`, function (siteWidth) {
        localStorage.setItem(`tabsCategory`, JSON.stringify([true, false]));
        tabs.openSectionProduct(siteWidth);
      });
    } else {
      button.addEventListener(`click`, function (siteWidth) {
        localStorage.setItem(`tabsSubCategory`, JSON.stringify([true, false]));
        tabs.openSectionProduct(siteWidth);
      });
    }
  }
}

if (main.classList.contains(`main-checkout`)) {

  const formCheckoutCart = main.querySelector(`.checkout__form`);

  if (formCheckoutCart !== null) {
    const checkoutCart = new CheckoutCart(formCheckoutCart);
    checkoutCart.init();
  }
}

if (main !== null) {

  if (main.querySelector(`.catalog`) !== null) {
    if (main.querySelector(`.catalog__button-wrap`) !== null) {
      const category = new Category(main, siteWidth);
      category.init();
    }
  }

  if (main.querySelector(`.main-product__container`) !== null) {

    const productBox = main.querySelector(`.main-product__container`);
    const product = new Product(productBox);
    product.init(main);
  }


  if (main.classList.contains(`main-common`)) {
    const containerSliderCommon = main.querySelector(`.slider-common__container`);
    const trackSlider = containerSliderCommon.querySelector(`.slider-common__track`);
    const itemSliderCommon = containerSliderCommon.querySelectorAll(`.slider-common__item`);
    const btnPrev = containerSliderCommon.querySelector(`.slider-common__btn--prev`);
    const btnNext = containerSliderCommon.querySelector(`.slider-common__btn--next`);
    const slidesToShowCommon = 1;
    const slidesToScrollCommon = 1;
    const PERIOD_SLIDER_COMMON = 5000;
    const START_TIME_SLIDER_COMMON = 2000;

    const sliderDotLictCommon = containerSliderCommon.querySelector(`.slider-common__dots-list`);

    const sliderCommon = new Slider(containerSliderCommon, trackSlider, itemSliderCommon, btnPrev, btnNext, slidesToShowCommon, slidesToScrollCommon, PERIOD_SLIDER_COMMON, START_TIME_SLIDER_COMMON, sliderDotLictCommon);
    sliderCommon.init();
    sliderCommon.initScrollSlider();

    const arrivals = main.querySelector(`.main-common__arrivals--arrivals`);
    getSliderStandartArrivals(arrivals, siteWidth, Slider);

    const best = main.querySelector(`.main-common__arrivals--best`);
    getSliderStandartArrivals(best, siteWidth, Slider);
  }

  if (main.classList.contains(`main-category`)) {
    tabs.init(siteWidth);
  }

  if (main.classList.contains(`main-collection`)) {
    if (siteWidth < 1186) {
      const latestProduct = main.querySelector(`.main-category__arrivals--arrivals`);
      if (latestProduct !== null) {
        getSliderStandartArrivals(latestProduct, siteWidth, Slider);
      }
    }
  }
  if (main.classList.contains(`main-product`)) {
    if (siteWidth < 768) {
      const productImg = main.querySelector(`.main-product__img-preview`);
      const productReview = main.querySelector(`.main-product__reviews`);
      if (productImg !== null) {
        const containerSliderProductImg = productImg.querySelector(`.main-product__preview-container`);
        const trackSliderProductImg = containerSliderProductImg.querySelector(`.main-product__preview-list`);
        const itemSliderProductImg = containerSliderProductImg.querySelectorAll(`.main-product__preview-wrap`);
        const btnWrapProductImg = productImg.querySelector(`.arrivals__button-wrap`);
        const btnPrevProductImg = btnWrapProductImg.querySelector(`.button-slider__button--prev`);
        const btnNextProductImg = btnWrapProductImg.querySelector(`.button-slider__button--next`);
        let slidesToShowProductImg = 2;
        const slidesToScrollProductImg = 1;

        const PERIOD_SLIDER_LATEST = 5000;
        const START_TIME_SLIDER_LATEST = 2000;
        const sliderDotLictLATEST = null;

        const sliderProductImg = new Slider(containerSliderProductImg, trackSliderProductImg, itemSliderProductImg, btnPrevProductImg, btnNextProductImg, slidesToShowProductImg, slidesToScrollProductImg, PERIOD_SLIDER_LATEST, START_TIME_SLIDER_LATEST, sliderDotLictLATEST);
        sliderProductImg.init();
      }
      if (productReview !== null) {
        const containerSliderReview = productReview.querySelector(`.arrivals__container`);
        const trackSliderReview = containerSliderReview.querySelector(`.reviews__list`);
        const itemSliderReview = containerSliderReview.querySelectorAll(`.reviews__item-wrap`);
        const btnWrapReview = productReview.querySelector(`.arrivals__button-wrap`);
        const btnPrevReview = btnWrapReview.querySelector(`.button-slider__button--prev`);
        const btnNextReview = btnWrapReview.querySelector(`.button-slider__button--next`);
        let slidesToShowReview = 1;
        const slidesToScrollReview = 1;

        const PERIOD_SLIDER_REVIEW = 5000;
        const START_TIME_SLIDER_REVIEW = 2000;
        const sliderDotLictReview = null;

        const sliderProductReview = new Slider(containerSliderReview, trackSliderReview, itemSliderReview, btnPrevReview, btnNextReview, slidesToShowReview, slidesToScrollReview, PERIOD_SLIDER_REVIEW, START_TIME_SLIDER_REVIEW, sliderDotLictReview);
        sliderProductReview.init();
      }
    }
    const bestCollection = main.querySelector(`.main-product__accompanying`);
    if (siteWidth < 1186) {
      if (bestCollection !== null) {
        getSliderStandartArrivals(bestCollection, siteWidth, Slider);
      }
    }
  }
  if (siteWidth < 1186) {
    const latestProduct = main.querySelector(`.main-product__similar`);
    if (latestProduct !== null) {
      getSliderStandartArrivals(latestProduct, siteWidth, Slider);
    }
  }
} else {
  if (document.querySelector(`.catalog`) !== null) {
    if (document.querySelector(`.catalog__button-img`) !== null) {
      const category = new Category(document, siteWidth);
      category.init();
    }
  }
}

document.addEventListener(`click`, (evt) => {
  if (evt.target !== main.querySelector(`.filter__button--red`)) {
    localStorage.setItem(`scrollTab`, `end`);
  }
});
