import GalleryRoll from "./gallery_roll.js";
import { removeOptionWidth } from "../utils.js";
import { createOptionWidth } from "../utils.js";
import { disableButton } from "../utils.js";
import { anableButton } from "../utils.js";
// import { getOneIdCollection } from "../utils.js";
import Popup from "./popup.js";

export default class Product {
  constructor(productContainer) {
    this.productContainer = productContainer;

    this.material = this.productContainer.querySelector(`.main-product__form`).dataset.material;
    this.onlyRoll = this.productContainer.querySelector(`.form-product__only-roll`);
    this.productPriceText = this.productContainer.querySelector(`.main-product__price-parent`);
    this.boxInfoRoll = this.productContainer.querySelector(`.main-product__info-wrap`);
    this.boxOnlyRoll = this.productContainer.querySelector(`.form-product__only-roll`);
    this.boxInfoValueRoll = this.productContainer.querySelector(`.form-product__info-wrap--disposition`);
    this.inputOpt = this.productContainer.querySelector(`#opt`);
    this.inputRetail = this.productContainer.querySelector(`#retail`);
    if (this.material == 'roll' || this.material == 'tile') {
      this.widths = JSON.parse(this.productContainer.querySelector(`.main-product__form`).dataset.widths);
    }
    this.button = this.productContainer.querySelector(`.form-product__button`);
    this.buttonBox = this.productContainer.querySelector(`.form-product__button-wrapper`);
    this.boxOptionWidth = this.productContainer.querySelector(`.form-product__select-width--product`);
    this.buttonCheap = this.productContainer.querySelector(`.main-product__cheap-option`);
    this.modalCheap = this.productContainer.querySelector(`#modal-cheap`);
    if (this.modalCheap !== null) {
      this.popupCheap = new Popup(this.modalCheap);
    }

    if (this.productContainer.querySelector(`.main-product__img-wrap`) !== null) {
      this.visible = this.productContainer.querySelector(`.tabs__visible`);
      this.imgPreview = this.productContainer.querySelectorAll(`.main-product__picture`);
      // this.imgBig = this.productContainer.querySelectorAll(`.main-product__img-common`);
      this.imgBig = this.productContainer.querySelectorAll(`.main-product__img-box`);
    }

    this.buttonFaqPayment = this.productContainer.querySelector(`.faq__payment`);
    this.buttonFaqAddress = this.productContainer.querySelector(`.faq__address`);
    this.buttonFaqSample = this.productContainer.querySelector(`.faq__sample`);
    this.buttonFaqReturnPayment = this.productContainer.querySelector(`.faq__return-payment`);
    this.buttonFaqReturnProduct = this.productContainer.querySelector(`.faq__return-product`);
    this.modalPayment = this.productContainer.querySelector(`#modal-faq-payment`);
    this.modalAddress = this.productContainer.querySelector(`#modal-faq-address`);
    this.modalSample = this.productContainer.querySelector(`#modal-faq-sample`);
    this.modalReturnPayment = this.productContainer.querySelector(`#modal-faq-return-payment`);
    this.modalReturnProduct = this.productContainer.querySelector(`#modal-faq-return-product`);


    this.inputOptClickHandler = this.inputOptClickHandler.bind(this);
    this.inputRetailClickHandler = this.inputRetailClickHandler.bind(this);
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.buttonDisableClickHandler = this.buttonDisableClickHandler.bind(this);
    this.correctValueRetailTile = this.correctValueRetailTile.bind(this);
    this.correctValueOptTile = this.correctValueOptTile.bind(this);
    this.validateInputPoint = this.validateInputPoint.bind(this);
    this.buttonCheapClickHandler = this.buttonCheapClickHandler.bind(this);
    this.buttonFuqClickHandler = this.buttonFuqClickHandler.bind(this);
    this.getApiReview = this.getApiReview.bind(this)
  }

  init(main) {
    if (this.productContainer.querySelector(`.main-product__img-wrap`) !== null) {
      this.imgPreview.forEach((item, i) => {
        this.setBigInPreview(item, this.imgBig[i]);
      });
    }

    this.setInitial();
    this.getCheckBox();

    document.querySelector(`#button-review`).addEventListener(`click`, this.getApiReview)

    const containerSimilar = main.querySelector(`.main-product__similar`);

    if (containerSimilar !== null) {
      const similar = new GalleryRoll(containerSimilar);
      similar.init();
    }

    if (this.buttonCheap !== null) {
      this.buttonCheap.addEventListener(`click`, this.buttonCheapClickHandler);
    }

    this.buttonFaqPayment.addEventListener(`click`, this.buttonFuqClickHandler.bind(this, this.modalPayment));
    this.buttonFaqAddress.addEventListener(`click`, this.buttonFuqClickHandler.bind(this, this.modalAddress));
    this.buttonFaqSample.addEventListener(`click`, this.buttonFuqClickHandler.bind(this, this.modalSample));
    this.buttonFaqReturnPayment.addEventListener(`click`, this.buttonFuqClickHandler.bind(this, this.modalReturnPayment));
    this.buttonFaqReturnProduct.addEventListener(`click`, this.buttonFuqClickHandler.bind(this, this.modalReturnProduct));
  }

  setValueForCheap() {
    if (this.material === 'roll') {
      this.productContainer.querySelectorAll(`.checkout__width-value`).textContent = this.productContainer.querySelector('#price-width').textContent;

      const checkboxList = this.productContainer.querySelectorAll(`.form-product__checkbox-input`);
      Array.from(checkboxList).forEach((item) => {
        if (item.checked) {
          const res = this.productContainer.querySelector(`label[for=${item.id}]`).textContent;
          this.productContainer.querySelector(`#key-price`).textContent = res;
        }
      });

      this.productContainer.querySelector(`#value-price`).textContent = this.productPriceText.textContent;
    }

    if (this.productContainer.querySelector('#price-width') !== null) {
      this.productContainer.querySelector(`input[name=width]`).value = this.productContainer.querySelector('#price-width').textContent;
    } else {
      this.productContainer.querySelector(`input[name=width]`).value = ' Для этого товара свойство не поддерживается';
    }

    if (this.productContainer.querySelector(`#key-price`) !== null) {
      this.productContainer.querySelector(`input[name=roll]`).value = this.productContainer.querySelector(`#key-price`).textContent;
    } else {
      this.productContainer.querySelector(`input[name=roll]`).value = ' Для этого товара свойство не поддерживается';
    }
  }

  setBigInPreview(imgPreview, imgBig) {
    imgPreview.addEventListener(`click`, () => {
      this.imgBig.forEach((item) => {
        if (item.classList.contains('tabs__visible')) {
          item.classList.remove('tabs__visible');
        }
      });
      this.imgPreview.forEach((item) => {
        if (item.classList.contains('main-product__picture--activ')) {
          item.classList.remove('main-product__picture--activ');
        }
      });

      imgBig.classList.add('tabs__visible');
      imgPreview.classList.add('main-product__picture--activ');
    });
  }

  // setTextparent(element) {
  //   const clone = element.cloneNode(true);
  //   Array.prototype.slice.call(clone.children).forEach((child) => {
  //     child.nodeType !== Node.TEXT_NODE && clone.removeChild(child);
  //   });
  //   return clone;
  // }

  createDataDescriptionRoll(width) {
    return `Цена указана для ширины ${width} метра. Чтобы узнать цены на другие ширины - выберете нужную ширину и способ отгрузки: Целыми рулонами или в нарезку`;
  }

  getWidthNote(i) {
    if (this.productContainer.querySelector(`.advantages__question`) !== null) {
      const dataDescriptionRoll = this.productContainer.querySelector(`.advantages__question`);
      dataDescriptionRoll.dataset.descriptionRoll = this.createDataDescriptionRoll(i);
      this.productContainer.querySelector('#price-width').textContent = i;
    }
  }

  createInfoRoll(namotka) {
    const html = `<span class="main-product__text-form">В рулоне - </span>
    <span class="main-product__value-form">${namotka}</span>
    <span class="main-product__izm-form"> м2</span>`;
    return this.boxInfoRoll.insertAdjacentHTML("beforeend", html);
  }

  removeInfoRoll() {

    if (this.boxInfoRoll.hasChildNodes()) {

      while (this.boxInfoRoll.firstChild) {
        this.boxInfoRoll.removeChild(this.boxInfoRoll.firstChild)
      }
    }
  }

  createOnlyRoll() {
    const html = `<span class="form-product__only-text">Эта расцветка доступна только целыми рулонами</span>`;
    return this.boxOnlyRoll.insertAdjacentHTML("beforeend", html);
  }

  removeOnlyRoll() {
    if (this.boxOnlyRoll.hasChildNodes()) {

      while (this.boxInfoRoll.firstChild) {
        this.boxOnlyRoll.removeChild(this.boxInfoRoll.firstChild)
      }
    }
  }

  createInfoValueRoll() {
    const html = `<span class="form-product__text-zakaz form-product__text-zakaz--disposition">После выбора ширины введите кол-во рулонов. Поле "м2" формируется автоматически и недоступно для редактирования </span> `;
    return this.boxInfoValueRoll.insertAdjacentHTML("beforeend", html);
  }

  removeInfoValueRoll() {
    if (this.boxInfoValueRoll.hasChildNodes()) {

      while (this.boxInfoValueRoll.firstChild) {
        this.boxInfoValueRoll.removeChild(this.boxInfoValueRoll.firstChild)
      }
    }
  }

  removeErrorText(box) {
    if (box.hasChildNodes()) {

      while (box.firstChild) {
        box.removeChild(box.firstChild)
      }
    }
  }

  createErrorText(box, text) {
    const html = `<span class="form-product__text-span">${text}</span>`;
    return box.insertAdjacentHTML("beforeend", html);
  }

  getValueRetailforRoll(namotka, count) {
    this.inputOpt.value = count;
    this.inputRetail.value = namotka * count;
  }

  setInitialPrice(cut) {
    cut.sort((a, b) => a.price - b.price);
    this.productPriceText.textContent = cut[0].price;
    const widthNote = cut[0].nameWidth;
    const valueRoll = cut[0].valueRoll;
    this.getWidthNote(widthNote);
    if (-cut[0].cut) {
      this.removeInfoRoll();
      this.removeOnlyRoll();
      this.removeInfoValueRoll();
    } else {
      this.createInfoRoll(valueRoll);
      this.createOnlyRoll();
      this.createInfoValueRoll();
      this.getValueRetailforRoll(valueRoll, 1);
    }
  }

  disabledError() {
    Array.from(this.productContainer.querySelectorAll(`input`)).map((i) => {
      if (i.classList.contains(`form-product__input-zakaz--error`)) {
        i.classList.remove(`form-product__input-zakaz--error`);
      }
    });

    this.removeErrorText(this.productContainer.querySelector(`.form-product__text-error`));
  }

  setInitial() {
    switch (this.material) {
      case 'roll':
        // if (this.material == "roll") {

        if (this.widths.length > 0) {
          const cut = this.widths.filter((i) => i.cut == 1);

          disableButton(this.button);

          if (cut.length > 0) {
            this.setInitialPrice(cut)
            this.inputOpt.disabled = true;
            this.inputRetail.value = 1;
            this.productContainer.querySelector(`#cut-1`).checked = true;
            createOptionWidth(cut, this.boxOptionWidth);
            this.optionClickHahdler(cut);
            this.inputRetail.addEventListener(`input`, this.inputRetailClickHandler);
            this.buttonBox.addEventListener(`click`, this.buttonDisableClickHandler);

          } else {
            const cut = this.widths.filter((i) => i.cut == 0);
            this.setInitialPrice(cut);
            createOptionWidth(cut, this.boxOptionWidth);

            this.optionClickHahdler(this.widths);
            this.inputRetail.disabled = true;
            this.inputRetail.style.opacity = '0.6';
            this.productContainer.querySelector(`#cut-1`).disabled = true;
            this.productContainer.querySelector(`[for="cut-1"]`).style.opacity = '0.3';
            this.productContainer.querySelector(`#roll-1`).checked = true;
            this.optionClickCreateInfoRoll(cut);
            this.inputOpt.addEventListener(`input`, this.inputOptClickHandler);
            this.buttonBox.addEventListener(`click`, this.buttonDisableClickHandler);
          }
        }
        break;
      // } else {
      case 'tile':
        this.inputOpt.value = 1;
        this.inputRetail.value = this.widths.size;
        this.inputOpt.addEventListener(`input`, this.correctValueRetailTile.bind(this, this.widths));
        this.inputRetail.addEventListener(`input`, this.correctValueOptTile.bind(this, this.widths));
        if (this.inputRetail.readOnly) {
          this.inputRetail.readOnly = false;
        }
        if (!this.widths.palet) {
          this.inputRetail.readOnly = true;
        }
        break;
      case 'point':
        this.inputOpt.value = 1;
        this.inputOpt.addEventListener(`input`, this.validateInputPoint);
    }
  }

  getCheckBox() {
    if (this.material == "roll") {
      const checkboxList = this.productContainer.querySelectorAll(`.form-product__checkbox-input`);
      Array
        .from(checkboxList)
        .forEach((item) => item.addEventListener(`change`, this.checkboxChangeHandler));
    }
  }

  optionClickHahdler(cut) {
    this.productContainer.querySelector(`select`).addEventListener(`change`, (evt) => {
      const valueOption = evt.target.value;
      const price = (cut.find((i) => i.nameWidth == valueOption));
      if (price !== undefined) {
        this.productPriceText.textContent = price.price;
        if (price.cut) {
          this.inputRetail.value = 1;
        }
      }
      this.getWidthNote(valueOption);
      this.removeInfoRoll();
      anableButton(this.button);
      this.disabledError();
      this.buttonBox.removeEventListener(`click`, this.buttonDisableClickHandler);
    });
  }

  optionClickCreateInfoRoll(cut) {
    this.productContainer.querySelector(`select`).addEventListener(`change`, (evt) => {
      const valueOption = evt.target.value;
      const object = cut.find((i) => i.nameWidth == valueOption);

      if (Number(object.cut)) {
        this.inputOpt.value = '';
        this.inputRetail.value = '1';
      } else {
        this.createInfoRoll(object.valueRoll);
        this.getValueRetailforRoll(object.valueRoll, 1);
      }
    });
  }

  inputOptClickHandler(evt) {
    const value = evt.target.value;
    const valueRoll = this.productContainer.querySelector(`.main-product__value-form`).textContent;
    this.getValueRetailforRoll(valueRoll, value);
    this.validateInputs(value, evt);
    this.installationWidth();
  }

  inputRetailClickHandler(evt) {
    this.validateInputs(evt.target.value, evt);
    this.installationWidth();
  }

  checkboxChangeHandler(evt) {
    disableButton(this.button);

    this.disabledError();

    // if (evt.target.value == "рулонами") {
    if (evt.target.value === "false") {
      const cut = this.widths.filter((i) => i.cut == 0);
      this.removeInfoValueRoll();
      this.createInfoValueRoll();

      removeOptionWidth(this.boxOptionWidth);

      cut.sort((a, b) => a.price - b.price);
      this.productPriceText.textContent = cut[0].price;
      const widthNote = cut[0].nameWidth;
      const valueRoll = cut[0].valueRoll;
      this.getWidthNote(widthNote);
      this.removeInfoRoll();
      this.createInfoRoll(valueRoll);
      this.getValueRetailforRoll(valueRoll, 1);

      createOptionWidth(cut, this.boxOptionWidth);

      this.optionClickHahdler(cut);
      this.inputRetail.disabled = true;
      this.inputRetail.style.opacity = '0.6';
      this.inputOpt.disabled = false;
      this.inputOpt.style.opacity = '1';
      this.optionClickCreateInfoRoll(cut);
      this.inputOpt.addEventListener(`input`, this.inputOptClickHandler);
      this.buttonBox.addEventListener(`click`, this.buttonDisableClickHandler);

      const box = this.productContainer.querySelector(`.form-product__select-width--product`);
      const options = box.querySelectorAll(`option`);

      if (options.length > 0) {
        options[0].selected = true;
      }

    } else {
      const cut = this.widths.filter((i) => i.cut == 1);
      removeOptionWidth(this.boxOptionWidth);
      this.removeInfoValueRoll();

      cut.sort((a, b) => a.price - b.price);
      this.productPriceText.textContent = cut[0].price;
      const widthNote = cut[0].nameWidth;
      this.getWidthNote(widthNote);
      this.removeInfoRoll();

      createOptionWidth(cut, this.boxOptionWidth);
      this.optionClickHahdler(cut);

      this.inputRetail.disabled = false;
      this.inputRetail.style.opacity = '1';
      this.inputRetail.value = '1';
      this.inputOpt.disabled = true;
      this.inputOpt.style.opacity = '0.6';
      this.inputOpt.value = '';
      this.optionClickCreateInfoRoll(cut);
      this.buttonBox.addEventListener(`click`, this.buttonDisableClickHandler);

      const box = this.productContainer.querySelector(`.form-product__select-width--product`);
      const options = box.querySelectorAll(`option`);

      if (options.length > 0) {
        options[0].selected = true;
      }

      this.inputRetail.addEventListener(`input`, this.inputRetailClickHandler);
    }
  }

  validateInputs(value, evt) {
    const box = this.productContainer.querySelector(`.form-product__text-error`);
    const num = String(Number(value));
    const validatesData = [
      { condition: value <= 0, errorText: ' Допустимы только положительные значения' },
      { condition: !num.match(/[0-9]/g), errorText: ' Значение должно быть цифрами' }
    ];
    const dataError = [];
    validatesData.map((i) => {

      if (i.condition) {
        dataError.push(i);
      }
    });

    if (dataError.length > 0) {
      disableButton(this.button);
      this.removeErrorText(box);

      if (!evt.target.classList.contains(`form-product__input-zakaz--error`)) {
        evt.target.classList.add(`form-product__input-zakaz--error`);
      }

      dataError.map((item) => {
        this.createErrorText(box, item.errorText)
      });
    } else {
      anableButton(this.button);
      this.removeErrorText(box);
      this.buttonBox.removeEventListener(`click`, this.buttonDisableClickHandler);

      if (evt.target.classList.contains(`form-product__input-zakaz--error`)) {
        evt.target.classList.remove(`form-product__input-zakaz--error`);
      }
    }
  }

  buttonDisableClickHandler(evt) {
    evt.preventDefault();
    const box = evt.target;

    this.createPopupWidthNote(box);
    box.removeEventListener(`click`, this.buttonDisableClickHandler);
    setTimeout(this.removePopupWidthNote.bind(this, box), 1500);
  }

  createPopupWidthNote(box) {
    const html = `<p id="width-Note">Выберете нужную ширину</p>`;
    box.insertAdjacentHTML("beforeend", html);
    box.querySelector(`#width-Note`).style.cssText = "border: 2px solid blue; border-radius: 5px; font-size: 14px; padding: 5px; width: 120px; color: red; background-color: #949ca4; z-index: 2000; position: absolute; top: -80px; right:-20px; text-align: center;"
  }

  removePopupWidthNote(box) {
    box.querySelector(`#width-Note`).remove();
    box.addEventListener(`click`, this.buttonDisableClickHandler);
  }

  installationWidth() {

    if (this.productContainer.querySelector(`#product select`).value == '') {

      const width = this.productContainer.querySelector('#price-width').textContent;
      Array.from(this.productContainer.querySelector(`#product select`)).map((i) => {
        if (i.value == width) {
          i.selected = true;
        }
      });
    }
  }

  correctValueRetailTile(data, evt) {
    this.inputRetail.value = (Number(this.inputOpt.value * data.size)).toFixed(2);
    this.validateInputs(evt.target.value, evt);
  }

  correctValueOptTile(data, evt) {
    this.inputOpt.value = Math.round(this.inputRetail.value / data.size);
    this.inputRetail.addEventListener('blur', this.correctValueRetailTile.bind(this, data));
    this.validateInputs(evt.target.value, evt);
  }

  validateInputPoint(evt) {
    this.validateInputs(evt.target.value, evt);
  }

  buttonCheapClickHandler() {

    this.setValueForCheap();
    this.popupCheap.setPopupOpen();
    this.popupCheap.setOverlay();

    const form = document.querySelector(`#form-cheap`);
    form.onsubmit = async (e) => {
      e.preventDefault();
      const button = form.querySelector(`.modal-cart__button--cheap`);
      disableButton(button);

      const response = await fetch('https://tdpsm.ru/index.php?route=product/product/cheap', {
        method: 'POST',
        body: new FormData(form)
      });

      const result = await response.json();

      if (result.error === '') {
        document.querySelector('.overlay').classList.remove('popup__overlay');
        document.querySelector('.modal-cart--cheap').classList.remove('popup__show');
        this.createPopupCheapSuccess();
        setTimeout(this.removePopupCheapSuccess.bind(this), 2100);
        anableButton(button);
      }
      else {
        form.querySelector('.account__error--captcha').textContent = result.error;
        anableButton(button);
      }
    };
  }

  createPopupCheapSuccess() {
    const html = `<div class="popup modal-cart popup__show" id="cheap-note">Сообщение успешно отправлено</div>`;
    document.querySelector(`main`).insertAdjacentHTML("afterbegin", html);
    document.querySelector(`#cheap-note`).style.cssText = "background-color: coral;";
  }

  removePopupCheapSuccess() {
    document.querySelector(`#cheap-note`).remove();
  }

  buttonFuqClickHandler(box, evt) {
    evt.preventDefault();
    const modal = new Popup(box);
    modal.setPopupOpen();
    modal.setOverlay();
  }

  getApiReview(evt) {
    const id = document.querySelector(`input[name='product_id']`).value;
    const form = document.querySelector(`#form-review`);
    const button = form.querySelector(`#button-review`);
    disableButton(button);
    button.textContent = `Отправляется...`;
    evt.preventDefault();
    fetch(`https://tdpsm.ru/index.php?route=product/product/write&product_id=${id}`,
      {
        method: 'POST',
        body: new FormData(form)
      })
      .then(response => response.json())
      .then((result) => {
        if (typeof result.error === 'undefined') {
          console.log('ss', result.success)
          this.createPopupReviewSuccess(result.success);

          setTimeout(this.removePopupReviewSuccess.bind(this), 2500);
          anableButton(button);
          button.textContent = `Отправить Отзыв`;
          Array.from(form.querySelectorAll(`input`)).forEach((input) => {
            input.value = '';
          });
          form.querySelector(`textarea`).value = '';
        } else {
          anableButton(button);
          button.textContent = `Отправить Отзыв`;
          this.createPopupReviewSuccess(result.error);

          setTimeout(this.removePopupReviewSuccess.bind(this), 2100);
        }
      });
  }

  createPopupReviewSuccess(text) {
    const html = `<div class="popup modal-cart popup__show" id="wishlist-Note">${text}</div>`;
    document.querySelector(`main`).insertAdjacentHTML("afterbegin", html);
    document.querySelector(`#wishlist-Note`).style.cssText = "background-color: coral;";
  }

  removePopupReviewSuccess() {
    document.querySelector(`#wishlist-Note`).remove();
  }
}
