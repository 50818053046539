import { createOptionWidth } from "../utils.js";
import { removeOptionWidth } from "../utils.js";
import { disableButton } from "../utils.js";
import { anableButton } from "../utils.js";

export default class GalleryRoll {
  constructor(container) {
    this.container = container;

    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.buttonDisableClickHandler = this.buttonDisableClickHandler.bind(this)
  }

  init() {
    if (this.container.dataset.material == "roll") {

      const gallery = this.container.querySelectorAll(`.product__item-wrap`);

      if (gallery.length > 0) {
        const products = [];
        Array.from(gallery).map((item) => {
          products.push(JSON.parse(item.dataset.widths));
        });

        const dataWidth = [];
        const dataRoll = [];

        products.map((i, index) => {
          this.getLoading(i, gallery[index]);

          dataWidth[index] = [];
          dataRoll[index] = [];
          const dataCombi = {};

          i.forEach((item) => {
            dataWidth[index].push(item.nameWidth);
            dataRoll[index].push(item.valueRoll);
            dataWidth[index].forEach((el, n) => {
              dataCombi[el] = dataRoll[index][n]
            });
          });
          this.setNoteRoll(gallery[index], dataCombi);
        });
      }
    }
  }

  getLoadingPrice(box, price) {
    return box.textContent = price;
  }

  setNoteRoll(i, data) {
    if (i.querySelector(`.form-product__img-question`) !== null) {
      i.querySelector(`.form-product__img-question`).dataset.descriptionRoll = this.createNoteRoll(data);
    }
  }

  createNoteRoll(data) {
    const html = []
    for (let key in data) {
      html.push(`ширина ${key}м - ${data[key]}м2; `);
    }
    return html.join('');
  }

  createNoteCut(data, el) {
    const cut = data.filter((i) => i.cut == 1);
    const noCut = data.filter((i) => i.cut == 0);

    if (cut.length == noCut.length) {
      return el.querySelector(`.form-product__text-rol`).textContent = `Все ширины режутся в размер`;
    } else if (cut.length == 0) {
      return el.querySelector(`.form-product__text-rol`).textContent = `Только целыми рулонами`;
    } else {
      return el.querySelector(`.form-product__text-rol`).textContent = `Есть ширины режется в размер`;
    }
  }

  getWidthNote(i, width) {
    if (i.querySelector(`.product__price-width`) !== null) {
      i.querySelector(`.product__price-width`).textContent = width + ' м';
    }
  }

  getLoading(data, i) {
    const boxPrice = i.querySelector(`.product__price-parent`);
    const inputCut = i.querySelector(`input[value="режется"]`);
    const inputNoCut = i.querySelector(`input[value="рулонами"]`);
    const labelCut = i.querySelector(`.form-product__checkbox-label--retail`);
    const boxOptionWidth = i.querySelector(`.form-product__select-width`);
    const button = i.querySelector(`.product__button`);
    const boxButton = i.querySelector(`.product__button-wrapper`);

    if (data.length > 0) {
      const cut = data.filter((i) => i.cut == 1);
      this.createNoteCut(data, i);
      cut.sort((a, b) => a.price - b.price);
      if (cut.length > 0) {
        this.getLoadingPrice(boxPrice, cut[0].price);
        inputCut.checked = true;

        removeOptionWidth(boxOptionWidth);
        createOptionWidth(cut, boxOptionWidth);
        disableButton(button);

        this.getWidthNote(i, cut[0].nameWidth);
        this.checkboxChangeHandler(i, button, boxOptionWidth, data);
        boxButton.addEventListener(`click`, this.buttonDisableClickHandler);
        this.optionClickHahdler(cut, i, boxPrice, button);
      } else {
        const cut = data.filter((i) => i.cut == 0);
        cut.sort((a, b) => a.price - b.price);
        this.getLoadingPrice(boxPrice, cut[0].price);
        inputNoCut.checked = true;
        inputCut.disabled = true;
        labelCut.style.opacity = '0.3';
        removeOptionWidth(boxOptionWidth);
        createOptionWidth(cut, boxOptionWidth);
        disableButton(button);
        this.getWidthNote(i, cut[0].nameWidth);

        this.checkboxChangeHandler(i, button, boxOptionWidth, data);
        boxButton.addEventListener(`click`, this.buttonDisableClickHandler);
        this.optionClickHahdler(cut, i, boxPrice, button);
      }
    }
  }

  optionClickHahdler(cut, i, boxPrice, button) {
    i.querySelector(`select`).addEventListener(`change`, (evt) => {
      const valueOption = evt.target.value;
      const boxButton = i.querySelector(`.product__button-wrapper`);
      const price = (cut.find((i) => i.nameWidth == valueOption));
      if (price !== undefined) {
        boxPrice.textContent = price.price;
      }

      anableButton(button);
      this.getWidthNote(i, valueOption);
      boxButton.removeEventListener(`click`, this.buttonDisableClickHandler);
    });
  }

  checkboxChangeHandler(i, button, boxOptionWidth, data) {
    const checkboxList = i.querySelectorAll(`.form-product__checkbox-input`);
    const boxPrice = i.querySelector(`.product__price-parent`);
    const boxButton = i.querySelector(`.product__button-wrapper`);
    Array
      .from(checkboxList)
      .forEach((item) => item.addEventListener(`change`, (evt) => {
        disableButton(button);
        removeOptionWidth(boxOptionWidth);
        if (evt.target.value == "рулонами") {
          const cut = data.filter((i) => i.cut == 0);
          createOptionWidth(cut, boxOptionWidth);
          const options = i.querySelectorAll(`option`);

          if (options.length > 0) {
            options[0].selected = true;
          }
          cut.sort((a, b) => a.price - b.price);
          this.getWidthNote(i, cut[0].nameWidth);
          this.getLoadingPrice(boxPrice, cut[0].price);

          boxButton.addEventListener(`click`, this.buttonDisableClickHandler);
          this.optionClickHahdler(cut, i, boxPrice, button);
        } else {
          const cut = data.filter((i) => i.cut == 1);
          createOptionWidth(cut, boxOptionWidth);
          const options = i.querySelectorAll(`option`);

          if (options.length > 0) {
            options[0].selected = true;
          }
          cut.sort((a, b) => a.price - b.price);
          this.getWidthNote(i, cut[0].nameWidth);
          this.getLoadingPrice(boxPrice, cut[0].price);

          boxButton.addEventListener(`click`, this.buttonDisableClickHandler);
          this.optionClickHahdler(cut, i, boxPrice, button);
        }
      }));
  }

  buttonDisableClickHandler(evt) {
    evt.preventDefault();
    const box = evt.target;
    this.createPopupWidthNote(box);
    box.removeEventListener(`click`, this.buttonDisableClickHandler);
    setTimeout(this.removePopupWidthNote.bind(this, box), 1500);
  }

  createPopupWidthNote(box) {
    const html = `<p id="width-Note">Выберете нужную ширину</p>`;
    box.insertAdjacentHTML("beforeend", html);
    box.querySelector(`#width-Note`).style.cssText = "border: 2px solid blue; border-radius: 5px; font-size: 14px; padding: 5px; width: 120px; color: red; background-color: #949ca4; z-index: 2000; position: absolute; top: -80px; right:-20px; text-align: center;"
  }

  removePopupWidthNote(box) {
    box.querySelector(`#width-Note`).remove();
    box.addEventListener(`click`, this.buttonDisableClickHandler);
  }
}