import { disableButton, anableButton } from "../utils.js";
import Api from "./api.js";

export default class CheckoutCart {
  constructor(container) {
    this.container = container;

    this.list = this.container.querySelectorAll(`.checkout__wrap`);
    this.buttonUpdata = this.container.querySelector(`.checkout__button--update`);
    this.buttonContinue = this.container.querySelector(`.checkout__button--blue`);
    this.buttonCheck = this.container.querySelector(`.checkout__button--red`);
    this.buttonContainer = this.container.querySelector(`.checkout__button-container`)

    this.cartInputHendler = this.cartInputHendler.bind(this);
    this.updateButtonClickHandler = this.updateButtonClickHandler.bind(this);
    this.inputReadonlyClickHandler = this.inputReadonlyClickHandler.bind(this);
    this.buttonDisableClickHandler = this.buttonDisableClickHandler.bind(this);
    this.correctValueRetailTile = this.correctValueRetailTile.bind(this);
    this.correctValueOptTile = this.correctValueOptTile.bind(this);
    this.correctValueRetailRoll = this.correctValueRetailRoll.bind(this);
    // this.updateDisableClickHandler = this.updateDisableClickHandler.bind(this);
    this.buttonResetProductClickHendler = this.buttonResetProductClickHendler.bind(this);
  }

  init() {

    if (this.list.length > 0) {
      Array.from(this.list).forEach((item) => {
        const data = JSON.parse(item.dataset.cart);
        item.querySelector(`.checkout__reset`).addEventListener(`click`, this.buttonResetProductClickHendler.bind(this, item));

        // if (data.material == 'point') {
        //   data.cut = 0;
        // }

        if (data.material == 'tile') {

          // if (data.cut === undefined) {
          //   data.cut = 1;
          // }
          if (data.palet === undefined) {
            data.palet = 1;
          }
        }

        this.retailCorrect(item, data); //удаляет инпут упаковками (для рулонов)
        //выставляет вариант отгрузки(рулоны, коробки, штуки)

        this.setWidthNote(item, data);  //выставляет ширину рулона

        this.setValueOpt(item, data);   //устанавливает в зависимости от типа материала, количества (1 или полное число), режется или нет, количество в инпут "опт"

        this.getPriseProduct(item, data); //устанавливает итоговую стоимость за конкретный товар


        const resultRet = Number(this.getValueRetail(item, data));
        if (item.querySelector(`#retail`) !== null) {
          item.querySelector(`#retail`).value = resultRet.toFixed(2); // устанавливает количество в зависимости от количество упаковок (рулонов)
        } else {
          item.querySelector(`#opt`).value = resultRet;
        }
      });
    }

    this.container.addEventListener('input', this.cartInputHendler);
    // this.container.querySelector(`.checkout__button--update`).addEventListener(`click`, this.updateButtonClickHandler);

    const totalPriceHeaderNode = document.querySelector(`#marker`);
    if (totalPriceHeaderNode !== null) {
      const totalPriceHeader = Math.floor(Number(totalPriceHeaderNode.textContent));
      if (totalPriceHeader !== this.compareResultPrice()) {
        this.updateButtonClickHandler();
      }
    }
  }

  validateInput(item) {
    item.style.cssText = "border-color: #c4c4c4; outline: none;";
    this.buttonUpdata.disabled = false;
    const num = String(Number(item.value));
    const validatesData = [
      { condition: item.value <= 0, errorText: 'Значение должно быть больше нуля' },
      { condition: !num.match(/[0-9]/g), errorText: 'Значение должно быть цифрами' }
    ];

    validatesData.map((i) => {
      if (i.condition) {
        item.style.borderColor = `red`;
        this.buttonUpdata.disabled = true;

        item.addEventListener(`focus`, () => {
          item.style.outline = `red`;
        });
      }
    });
  }

  retailCorrect(element, data) {
    const retail = element.querySelector(`#retail`);

    switch (data.material) {
      case 'roll':
        if (data.cut === '1') {
          element.querySelector(`.checkout__value--cut`).textContent = `режется в размер`;
          element.querySelector(`label[for=opt]`).remove();
          element.querySelector(`.checkout__label-zakaz--retail`).style.marginTop = `0`;
          if (retail.readOnly) {
            retail.readOnly = false;
          }
        } else {
          element.querySelector(`.checkout__value--cut`).textContent = `целыми рулонами`;
          if (retail.readOnly) {
            retail.readOnly = true;
          }
        }
        break;
      case 'tile':
        if (data.palet === '1') {
          element.querySelector(`.checkout__value--cut`).textContent = `коробками`;
          if (retail.readOnly) {
            retail.readOnly = false;
          }
        } else {
          element.querySelector(`.checkout__value--cut`).textContent = `целыми палетами`;
          if (retail.readOnly) {
            retail.readOnly = true;
          }
        }
        break;
      case 'point':
        element.querySelector(`.checkout__value--cut`).textContent = `штуками`;
      // element.querySelector(`label[for=retail]`).remove();
      // element.querySelector(`.checkout__label-zakaz--retail`).style.marginTop = `0`;

      // if (retail.readOnly) {
      //   retail.readOnly = false;
      // }
    }
  }

  getPriseProduct(element, data) {
    const price = element.querySelector(`#price`).textContent;

    if (data.material !== 'point') {
      return element.querySelector(`#product-total`).textContent = (Number(element.querySelector(`#retail`).value * price)).toFixed(2) + ' руб';
    } else {
      return element.querySelector(`#product-total`).textContent = element.querySelector(`#opt`).value * price + ' руб';
    }
  }

  getValueRetail(element, data) {

    if (element.querySelector(`#opt`) !== null) {

      switch (data.material) {
        case 'roll':
          if (data.cut === '1') {
            return element.querySelector(`#retail`).value;
          } else {
            return data.size * data.width * (element.querySelector(`#opt`)).value;
          }

        case 'tile':
          return (element.querySelector(`#opt`)).value * data.size;

        case 'point':
          return element.querySelector(`#opt`).value;
      }
    }

    return element.querySelector(`#retail`).value;
  }

  setValueOpt(element, data) {
    Array
      .from(element.querySelectorAll(`input`))
      .map((i) => {
        i.addEventListener(`click`, this.inputReadonlyClickHandler);
        if (i.id === 'opt') {
          // i.addEventListener(`input`, this.correctValueRetailTile.bind(this, element, data))
          if (element.querySelector(`#retail`) !== null) {
            const valueRetail = element.querySelector(`#retail`).value;
            if (valueRetail == 1) {
              switch (data.material) {
                case 'roll':
                  element.querySelector(`#retail`).value = (data.size * data.width);
                  element.querySelector(`#opt`).value = 1;
                  i.addEventListener(`input`, this.correctValueRetailRoll.bind(this, element, data));
                  break;
                case 'tile':
                  element.querySelector(`#retail`).value = data.size;
                  element.querySelector(`#opt`).value = 1;
                  i.addEventListener(`input`, this.correctValueRetailTile.bind(this, element, data))
                  break;
              }

              // setTimeout(() => this.container.submit(), 100);
            } else {
              switch (data.material) {
                case 'roll':
                  if (data.cut !== '1') {
                    element.querySelector(`#opt`).value = (element.querySelector(`#retail`).value / (data.size * data.width));
                    i.addEventListener(`input`, this.correctValueRetailRoll.bind(this, element, data));
                  }
                  break;
                case 'tile':
                  element.querySelector(`#opt`).value = (Number(element.querySelector(`#retail`).value / data.size).toFixed());
                  i.addEventListener(`input`, this.correctValueRetailTile.bind(this, element, data));
                  break;
              }
            }
          }
        } else {
          i.addEventListener(`input`, this.correctValueOptTile.bind(this, element, data));
        }
        // }
      });
  }

  correctValueOptTile(element, data) {
    if (data.material !== 'point') {
      if (data.material !== 'roll' && data.cut !== '1') {
        element.querySelector(`#opt`).value = Math.round(element.querySelector(`#retail`).value / data.size);

        element.querySelector(`#retail`).addEventListener(`blur`, this.correctValueRetailTile.bind(this, element, data));
      }
    }
  }

  correctValueRetailTile(element, data) {
    element.querySelector(`#retail`).value = (Number(element.querySelector(`#opt`).value * data.size).toFixed(2));
  }

  correctValueRetailRoll(element, data) {
    element.querySelector(`#retail`).value = (Number(element.querySelector(`#opt`).value * data.size * data.width).toFixed(2));
  }

  createPopupInputNote(box) {
    const html = `<p id="input-Note">Это поле формируется автоматически. Введите количество рулонов</p>`;
    box.insertAdjacentHTML("afterbegin", html);
    document.querySelector(`#input-Note`).style.cssText = "border: 2px solid blue; border-radius: 5px; font-size: 14px; padding: 5px; width: 140px; color: #fff; background-color: #949ca4; z-index: 2000; position: absolute; top: -30px; right: 90px; text-align: center;"
  }

  removePopupWidthNote(box) {
    box.querySelector(`#input-Note`).remove();
  }

  createPopupButtonNote(box) {
    const html = `<p id="button-Note">Нажмите кнопку ОБНОВИТЬ</p>`;
    box.insertAdjacentHTML("afterbegin", html);
    document.querySelector(`#button-Note`).style.cssText = "border: 2px solid blue; border-radius: 5px; font-size: 14px; padding: 5px; width: 140px; color: #fff; background-color: #949ca4; z-index: 2000; position: absolute; top: -30px; right: 50%; text-align: center; transform: translateX(50%);"
  }

  removePopupButtonNote(box) {
    box.querySelector(`#button-Note`).remove();
  }

  createPopupButtonUpdateNote(box) {
    const html = `<p id="update-Note">Исправьте ошибки при вводе количества</p>`;
    box.insertAdjacentHTML("afterbegin", html);
    document.querySelector(`#update-Note`).style.cssText = "border: 2px solid blue; border-radius: 5px; font-size: 14px; padding: 5px; width: 140px; color: #fff; background-color: #949ca4; z-index: 2000; position: absolute; top: -30px; right: 50%; text-align: center; transform: translateX(50%);"
  }

  setWidthNote(element, data) {
    data.material == 'roll' ? element.querySelector(`.checkout__width-value`).textContent = data.width : '';
  }

  cartInputHendler(evt) {
    this.validateInput(evt.target);
    disableButton(this.buttonContinue);
    disableButton(this.buttonCheck);
    this.buttonUpdata.addEventListener(`click`, this.updateButtonClickHandler);

    this.buttonContinue.closest('div').addEventListener(`click`, this.buttonDisableClickHandler);
  }

  updateButtonClickHandler() {
    anableButton(this.buttonContinue);
    anableButton(this.buttonCheck);
    this.buttonContinue.closest('div').removeEventListener(`click`, this.buttonDisableClickHandler);

    Array.from(this.list).forEach((item) => {
      const data = JSON.parse(item.dataset.cart);
      if (data.material !== 'point') {
        item.querySelector(`#retail`).value = (Number(this.getValueRetail(item, data))).toFixed(2);
      }

      this.getPriseProduct(item, data);

      Array.from(item.querySelectorAll(`input`)).map((i) => {
        if (i.id == 'opt' && data.material !== 'point') {
          i.name = '';
          // console.log('name удален')
        }
      });
    });

    this.container.submit();
  }

  inputReadonlyClickHandler(evt) {
    if (evt.target.matches('input')) {
      const input = evt.target;
      if (input.hasAttribute('readonly')) {
        const label = evt.target.closest('label');
        this.createPopupInputNote(label);
        setTimeout(this.removePopupWidthNote.bind(this, label), 2000);
      }
    }
  }

  buttonDisableClickHandler(evt) {
    this.createPopupButtonNote(evt.target);
    setTimeout(this.removePopupButtonNote.bind(this, evt.target), 1500);
  }

  buttonResetProductClickHendler(item) {
    const id = item.querySelector(`.checkout__reset`).dataset.cartId
    // const api = new Api('http://tdpsm/index.php?route=checkout/cart/remove');
    const api = new Api('https://tdpsm.ru/index.php?route=checkout/cart/remove');
    api.add(
      'key=' + id
    ).then((response) => {
      if (response.success) {

        if (window.location.search === '?route=checkout/cart') {
          location.reload();
        }
      }
    });
  }

  compareResultPrice() {

    if (this.list.length > 0) {
      let result = [];
      Array.from(this.list).forEach((item) => {
        const price = item.querySelector(`#product-total`).textContent;

        result.push(Math.floor(Number(price.replace(/[a-zа-яё]/gi, ''))))
      });
      return result.reduce((sum, current) => sum + current, 0);
    }
  }
}