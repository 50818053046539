import Api from "./api.js";

export default class Wishlist {
  constructor() {
    this.buttonWishlistClickHandler = this.buttonWishlistClickHandler.bind(this)
  }
  init() {
    Array
      .from(this.getWishlistNode())
      .map((item) => {
        item.addEventListener(`click`, this.buttonWishlistClickHandler.bind(this, item));
      });
  }

  getWishlistNode() {
    return document.querySelectorAll(`.product__link-button[title="добавить в избранное"]`);
  }

  buttonWishlistClickHandler(item, evt) {
    evt.preventDefault();

    this.getDataSetWichlist(item);
  }

  getDataSetWichlist(item) {
    const id = item.dataset.wishlist;
    const api = new Api('https://tdpsm.ru/index.php?route=account/wishlist/add');
    // const api = new Api('http://tdpsm/index.php?route=account/wishlist/add');
    api.add(
      'product_id=' + id
    ).then((response) => {
      if (response.success) {
        this.createPopupWishlistSuccess(response.success);
        setTimeout(this.removePopupWishlistSuccess.bind(this), 2100);
        this.actionMarker(response.total);
      }
    });
  }

  createPopupWishlistSuccess(text) {
    const html = `<div class="popup modal-cart popup__show" id="wishlist-Note">${text}</div>`;
    document.querySelector(`main`).insertAdjacentHTML("afterbegin", html);
    document.querySelector(`#wishlist-Note`).style.cssText = "background-color: coral;";
  }

  removePopupWishlistSuccess() {
    document.querySelector(`#wishlist-Note`).remove();
  }

  actionMarker(text) {
    const marker = document.querySelector(`#controls-wishlist`);
    const linkWishlist = document.querySelector(`#link-wishlist`);

    setTimeout(function () {
      if (!linkWishlist.classList.contains(`user-controls__link--marker`)) {
        linkWishlist.classList.add(`user-controls__link--marker`);
      }

      if (!marker.classList.contains(`user-controls__marker`)) {
        marker.classList.add(`user-controls__marker`);

      }
      marker.textContent = text;
    }, 100);
  }
}