export const createOptionWidth = (items, box) => {
  items.map((i) => {
    const html = `<option value="${i.nameWidth}">${i.nameWidth} м</option>`
    return box.insertAdjacentHTML("beforeend", html);
  });
}

export const removeOptionWidth = (box) => {

  if (box.childNodes.length > 2) {

    while (box.childNodes[2]) {
      box.removeChild(box.childNodes[2])
    }
  }
}

export const disableButton = (el) => {
  el.disabled = true;
  el.style.cssText = "opacity: 0.3; cursor: default; pointer-events: none;"
}

export const anableButton = (el) => {
  el.disabled = false;
  el.style.cssText = "opacity: 1; cursor: pointer; pointer-events: auto;"
}

export const getSliderStandartArrivals = (arrivals, siteWidth, Slider) => {
  const containerSliderArrivals = arrivals.querySelector(`.arrivals__container`);
  const trackSliderArrivals = containerSliderArrivals.querySelector(`.arrivals__list`);
  const itemSliderArrivals = containerSliderArrivals.querySelectorAll(`.product__item-wrap`);
  const btnWrapArrivals = arrivals.querySelector(`.arrivals__button-wrap`);
  const btnPrevArrivals = btnWrapArrivals.querySelector(`.button-slider__button--prev`);
  const btnNextArrivals = btnWrapArrivals.querySelector(`.button-slider__button--next`);
  let slidesToShowArrivals = 1;
  const slidesToScrollArrivals = 1;
  if (siteWidth > 1186) {
    slidesToShowArrivals = 3;
  } else if (siteWidth > 767) {
    slidesToShowArrivals = 2;
  }
  const PERIOD_SLIDER_ARRIVALS = 5000;
  const START_TIME_SLIDER_ARRIVALS = 2000;
  const sliderDotLictArrivals = null;

  const sliderArrivals = new Slider(containerSliderArrivals, trackSliderArrivals, itemSliderArrivals, btnPrevArrivals, btnNextArrivals, slidesToShowArrivals, slidesToScrollArrivals, PERIOD_SLIDER_ARRIVALS, START_TIME_SLIDER_ARRIVALS, sliderDotLictArrivals);
  sliderArrivals.init();
}

export const getDatasetId = (item) => {
  const id = item.dataset.buttonId;
  if (id !== undefined) {
    // console.log('id', id)
    return id;
  }
}

export const getDatasetQuantity = (item) => {

  let quantity = item.dataset.quantity;
  if (quantity !== undefined && quantity !== "") {
    // console.log('q ', quantity)
    return quantity;
  } else {
    // console.log('q ', quantity = '1')
    return '1';
  }
}

export const getOptions = (item, productId) => {

  if (item.querySelector(`#method-width-${productId}`) !== null && item.querySelector(`#cut-${productId}`) !== null) {

    const id = item.querySelector(`#method-width-${productId}`).value;
    const cut = item.querySelector(`#cut-${productId}`).checked;

    return '&method-width' + "=" + id + '&cut' + "=" + cut;
  } else {
    return null;
  }
}

export const actionMarker = (text) => {
  const marker = document.querySelector(`#marker`);
  const linkCart = document.querySelector(`.user-controls__link--cart`);

  setTimeout(function () {
    if (!linkCart.classList.contains(`user-controls__link--marker`)) {
      linkCart.classList.add(`user-controls__link--marker`);
    }

    if (!marker.classList.contains(`user-controls__marker`)) {
      marker.classList.add(`user-controls__marker`);
      marker.classList.add(`user-controls__marker--cart`);
      marker.textContent = text;
    }
  }, 100);
}