import Api from "./api.js";

export default class Compare {
  constructor() {
    this.buttonCompareClickHandler = this.buttonCompareClickHandler.bind(this)
  }
  init() {
    Array
      .from(this.getCompareNode())
      .map((item) => {
        item.addEventListener(`click`, this.buttonCompareClickHandler.bind(this, item));
      });
  }

  getCompareNode() {
    return document.querySelectorAll(`.product__link-button[title="добавить к сравнению"]`);
  }

  buttonCompareClickHandler(item, evt) {
    evt.preventDefault();

    this.getDataSetCompare(item);
  }

  getDataSetCompare(item) {
    // const id = item.dataset.compare;  надо установить data атрибут в шаблоны
    const api = new Api('https://tdpsm.ru/index.php?route=product/compare/add');
    // const api = new Api('http://tdpsm/index.php?route=product/compare/add');

    console.log('i ', item)
    api.add(''
      // 'product_id=' + id 
    ).then((response) => {
      console.log('res ', response)
      // if (response.success) {
      this.createPopupWishlistSuccess();
      setTimeout(this.removePopupWishlistSuccess.bind(this), 2100);
      // this.actionMarker(response.total);
      // }
    });
  }

  createPopupWishlistSuccess() {
    const html = `<div class="popup modal-cart popup__show" id="wishlist-Note"><p>Сервис временно не доступен. Страница находится в разработке</p></div>`;
    document.querySelector(`main`).insertAdjacentHTML("afterbegin", html);
    document.querySelector(`#wishlist-Note`).style.cssText = "background-color: coral;";
  }

  removePopupWishlistSuccess() {
    document.querySelector(`#wishlist-Note`).remove();
  }

  // actionMarker(text) {
  //   const marker = document.querySelector(`#controls-wishlist`);
  //   const linkWishlist = document.querySelector(`#link-wishlist`);

  //   setTimeout(function () {
  //     if (!linkWishlist.classList.contains(`user-controls__link--marker`)) {
  //       linkWishlist.classList.add(`user-controls__link--marker`);
  //     }

  //     if (!marker.classList.contains(`user-controls__marker`)) {
  //       marker.classList.add(`user-controls__marker`);

  //     }
  //     marker.textContent = text;
  //   }, 100);
  // }
}